import AlertRepository from "./alert.repository";
import CompetentAuthoritiesRepository from "./competentAuthority.repository";
import CriteriaRepository from "./criteria.repository";
import EventRepository from "./event.repository";
import LawFirmRepository from "./lawFirm.repository";
import LegalEntityRepository from "./legalEntity.repository";
import OfficialRepository from "./official.repository";
import ProcedureRepository from "./procedure.repository";
import ResidentAgentRepository from "./residentAgent.repository";
import RiskLevelRepository from "./riskLevel.repository";
import StadisticsRepository from "./stadistics.repository";
import SuperintendenceRepository from "./superintendence.repository";
import VariableInformationReportRepository from "./variableInformationReport.repository";

export const RepositoryFactory = {
    legalEntity: LegalEntityRepository,
    residentAgent: ResidentAgentRepository,
    superintendence: SuperintendenceRepository,
    competentAuthority: CompetentAuthoritiesRepository,
    lawFirm: LawFirmRepository,
    official: OfficialRepository,
    event: EventRepository,
    stadistics: StadisticsRepository,
    procedure: ProcedureRepository,
    variableInformationReport: VariableInformationReportRepository,
    criteriaRepository: CriteriaRepository,
    riskLevelRepository: RiskLevelRepository,
    alertRepository: AlertRepository
}