import Repository from "./repository";

const resource = "criteria";

export default {
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async update(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPaged({ page, take, search, type, state }) {
        let endpoint = `${resource}/paged?page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        if (type !== undefined && type !== null) {
            endpoint += `&type=${type}`;
        }
        if (state !== undefined && state !== null) {
            endpoint += `&state=${state}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getAll({ type }) {
        let endpoint = `${resource}/all`;
        if (type != null) {
            endpoint = `${endpoint}?type=${type}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getCount({ search, type, state }) {
        let endpoint = `${resource}/count?count=true`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        if (type !== undefined && type !== null) {
            endpoint += `&type=${type}`;
        }
        if (state !== undefined && state !== null) {
            endpoint += `&state=${state}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
}