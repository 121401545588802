import Repository from "./repository";

const resource = "variableInformationReport";

export default {
    async getFile(payload) {
        let endpoint = `${resource}/file`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getCompaniesByResidentAgent({filters, page, take}) {
        let endpoint = `${resource}/CompaniesByResidentAgent?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.residentAgentType) {
            endpoint = `${endpoint}&residentAgentType=${filters.residentAgentType}`;
        }

        if (filters.cur) {
            endpoint = `${endpoint}&cur=${filters.cur}`;
        }

        if (filters.curStatus) {
            endpoint = `${endpoint}&curStatus=${filters.curStatus}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getLegalEntitiesNotDoneOperationPA({filters, page, take}) {
        let endpoint = `${resource}/LegalEntitiesNotDoneOperationPA?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.societyType) {
            endpoint = `${endpoint}&societyType=${filters.societyType}`;
        }

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.countryJurisdiction) {
            endpoint = `${endpoint}&countryJurisdiction=${filters.countryJurisdiction}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.leStatus) {
            endpoint = `${endpoint}&leStatus=${filters.leStatus}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getLegalEntitiesDoneOperationPA({filters, page, take}) {
        let endpoint = `${resource}/LegalEntitiesDoneOperationPA?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.societyType) {
            endpoint = `${endpoint}&societyType=${filters.societyType}`;
        }

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.countryJurisdiction) {
            endpoint = `${endpoint}&countryJurisdiction=${filters.countryJurisdiction}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.leStatus) {
            endpoint = `${endpoint}&leStatus=${filters.leStatus}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getLegalEntitiesListedStockExchanges({filters, page, take}) {
        let endpoint = `${resource}/LegalEntitiesListedStockExchanges?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.country) {
            endpoint = `${endpoint}&country=${filters.country}`;
        }

        if (filters.countryJurisdiction) {
            endpoint = `${endpoint}&countryJurisdiction=${filters.countryJurisdiction}`;
        }

        if (filters.stockExchangeName) {
            endpoint = `${endpoint}&stockExchangeName=${filters.stockExchangeName}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.leStatus) {
            endpoint = `${endpoint}&leStatus=${filters.leStatus}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getMultilateralStateEntity({filters, page, take}) {
        let endpoint = `${resource}/MultilateralStateEntity?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.countryJurisdiction) {
            endpoint = `${endpoint}&countryJurisdiction=${filters.countryJurisdiction}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.leStatus) {
            endpoint = `${endpoint}&leStatus=${filters.leStatus}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getLegalEntityOwnedByState({filters,page, take}) {
        let endpoint = `${resource}/LegalEntityOwnedByState?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.leStatus) {
            endpoint = `${endpoint}&leStatus=${filters.leStatus}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getLegalEntityOperatingJurisdictionsIncludingPA({filters, page, take}) {
        let endpoint = `${resource}/LegalEntityOperatingJurisdictionsIncludingPA?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.societyType) {
            endpoint = `${endpoint}&societyType=${filters.societyType}`;
        }

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.countryJurisdiction) {
            endpoint = `${endpoint}&countryJurisdiction=${filters.countryJurisdiction}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.leStatus) {
            endpoint = `${endpoint}&leStatus=${filters.leStatus}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getReportInvitations({filters, page, take}) {
        let endpoint = `${resource}/ReportInvitations?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.residentAgentType) {
            endpoint = `${endpoint}&residentAgentType=${filters.residentAgentType}`;
        }

        if (filters.cur) {
            endpoint = `${endpoint}&cur=${filters.cur}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.curStatus) {
            endpoint = `${endpoint}&curStatus=${filters.curStatus}`;
        }

        if (filters.arEnabled) {
            endpoint = `${endpoint}&arEnabled=${filters.arEnabled}`;
        }

        if (filters.usedInvitation) {
            endpoint = `${endpoint}&usedInvitation=${filters.usedInvitation}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getFinalBeneficiaryProcesses({filters, page, take}) {
        let endpoint = `${resource}/FinalBeneficiaryProcesses?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.code) {
            endpoint = `${endpoint}&code=${filters.code}`;
        }

        if (filters.acId) {
            endpoint = `${endpoint}&acId=${filters.acId}`;
        }

        if (filters.fbStatus) {
            endpoint = `${endpoint}&fbStatus=${filters.fbStatus}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getFinalBeneficiaryReport({filters, page, take}) {
        let endpoint = `${resource}/FinalBeneficiaryReport?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;
        if (filters.Who !== undefined && filters.Who !== null) {
            endpoint = `${endpoint}&who=${filters.Who}`;
        }

        if (filters.country) {
            endpoint = `${endpoint}&country=${filters.country}`;
        }

        if (filters.countryJurisdiction) {
            endpoint = `${endpoint}&countryJurisdiction=${filters.countryJurisdiction}`;
        }

        if (filters.stockExchangeName) {
            endpoint = `${endpoint}&stockExchangeName=${filters.stockExchangeName}`;
        }

        if(filters.lawFirmId)
        {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if ( filters.reason !== undefined && filters.reason !== null) {
            endpoint = `${endpoint}&fbStatus=${filters.reason}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getLawFirmsReport({filters, page, take}) {
        let endpoint = `${resource}/LawFirms?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.curStatus) {
            endpoint = `${endpoint}&curStatus=${filters.curStatus}`;
        }

        if (filters.residentAgentType) {
            endpoint = `${endpoint}&residentAgentType=${filters.residentAgentType}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getSocietiesByResidentAgents({filters, page, take}) {
        let endpoint = `${resource}/SocietiesByResidentAgent?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.cur) {
            endpoint = `${endpoint}&cur=${filters.cur}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.clasification) {
            endpoint = `${endpoint}&clasification=${filters.clasification}`;
        }

        if (filters.residentAgentType) {
            endpoint = `${endpoint}&residentAgentType=${filters.residentAgentType}`;
        }

        if (filters.societyType) {
            endpoint = `${endpoint}&societyType=${filters.societyType}`;
        }

        if (filters.userId) {
            endpoint = `${endpoint}&userId=${filters.userId}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getLegalEntities({filters, page, take}) {
        let endpoint = `${resource}/LegalEntities?page=${page}&take=${take}&contentType=${filters.contentType}&startDate=${filters.startDate}&endDate=${filters.endDate}`;

        if (filters.folio) {
            endpoint = `${endpoint}&folio=${filters.folio}`;
        }

        if (filters.clasification) {
            endpoint = `${endpoint}&clasification=${filters.clasification}`;
        }

        if (filters.lawFirmId) {
            endpoint = `${endpoint}&lawFirmId=${filters.lawFirmId}`;
        }

        if (filters.name) {
            endpoint = `${endpoint}&name=${filters.name}`;
        }

        if (filters.residentAgentType) {
            endpoint = `${endpoint}&residentAgentType=${filters.residentAgentType}`;
        }

        if (filters.societyType) {
            endpoint = `${endpoint}&societyType=${filters.societyType}`;
        }

        if (filters.leStatus) {
            endpoint = `${endpoint}&leStatus=${filters.leStatus}`;
        }

        return this.getResponseReport(endpoint, filters.contentType);
    },
    async getResponseReport(endpoint, contentType) {
        let response = null;

        switch(contentType) {
            case "text/csv":
                response = await Repository.get(endpoint);
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                response = await Repository.getBlob(endpoint);
                break;
            case "application/pdf":
                response = await Repository.getBlob(endpoint);
                break;
        }
        
        return response;
    },
}