<template>
  <b-nav-text right v-if="notificationLength > 0">
    <span
      class="me-3"
      style="cursor: pointer"
      id="popoverTarget"
      @click="togglePopover"
      v-b-tooltip.hover
      :title="`${notificationLength} Notificaciones`"
    >
      <small>({{ notificationLength }})</small>
      <b-icon-bell-fill variant="danger"></b-icon-bell-fill>
    </span>

    <b-popover
      target="popoverTarget"
      :show.sync="popoverShow"
      @hidden="closePopover"
    >
      <template #title> Notificaciones </template>
      <b-list-group>
        <b-list-group-item
          v-for="(notification, i) in dynamicNotificationLawFirms"
          :key="i"
          @click="seeDynamicNotificationLawFirm(notification)"
        >
          <div>
            {{ formattedDate(notification.alertDynamicNotification.sendDate) }}
          </div>
          <div>{{ getType(notification.alertDynamicNotification.type) }}</div>
          <div v-if="notification.alertDynamicNotification.type === 1">
            {{ getFolios(notification.payloadDisconnectedLegalEntities) }}
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-popover>
  </b-nav-text>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { alertDynamicType } from "@/config/alertDynamicType";
import { societyType } from "@/config/society-type";
import moment from "moment";

export default {
  computed: {
    ...mapGetters("application", ["getAlertDynamicNotificationLawFirm"]),
    notificationLength() {
      return this.getAlertDynamicNotificationLawFirm.length;
    },
    dynamicNotificationLawFirms() {
      return this.getAlertDynamicNotificationLawFirm;
    },
  },
  data() {
    return {
      popoverShow: false,
    };
  },
  methods: {
    ...mapActions("application", ["setModalAlertDynamicNotificationLawFirm"]),
    togglePopover() {
      this.popoverShow = !this.popoverShow;
    },
    closePopover() {
      this.popoverShow = false;
    },
    seeDynamicNotificationLawFirm(notification) {
      this.setModalAlertDynamicNotificationLawFirm(notification);
      this.closePopover();
    },
    getType(value) {
      const t = alertDynamicType.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    formattedDate(date) {
      return date != null
        ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A")
        : "";
    },
    getFolios(payloadDisconnectedLegalEntities) {
      var folios = "";

      if (payloadDisconnectedLegalEntities) {
        folios = payloadDisconnectedLegalEntities.map(
          (entity) =>
            this.societyTypeDesriptions(entity.societyType) + " " + entity.folio
        );
      }

      return folios.toString();
    },
    societyTypeDesriptions(value) {
      return societyType.find((x) => x.value === value).text;
    },
  },
};
</script>
