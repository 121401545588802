<template>
    <div>
        <ShowMessageModal />
    </div>
</template>

<script>
import ShowMessageModal from "@/components/modals/ShowMessageModal.vue";

export default {
    name: "modals",
    components: {
        ShowMessageModal,
    },
};
</script>