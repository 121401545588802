<template>
    <b-navbar-nav class="fw-light ms-auto main-bar">
        <b-nav-item v-if="!isLogged" @click="authenticateOidc" right>
            Iniciar sesión
        </b-nav-item>
        <template v-if="isLogged">
            <NavbarNotification />
            <b-nav-text right>Hola, {{ name }}</b-nav-text>
            <b-nav-item @click="signOutOidc" class="logout" right>
                {{ $t("general.logout") }}
            </b-nav-item>
        </template>
    </b-navbar-nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavbarNotification from "../alerts/navbar-notifications/NavbarNotification.vue"

export default {
    name: "sso",
    components: {
        NavbarNotification
    },
    computed: {
        ...mapGetters("oidcStore", ["oidcUser", "oidcIsAuthenticated"]),
        isLogged() {
            return this.oidcIsAuthenticated;
        },
        name() {
            if (this.isLogged) {
                return `${this.oidcUser.given_name} ${this.oidcUser.family_name}`;
            }
            return "";
        },
    },
    methods: {
        ...mapActions("oidcStore", ["authenticateOidc", "signOutOidc"]),
    },
};
</script>

<style scoped>
.logout {
    margin-left: 10px;
    font-weight: bold;
}
</style>