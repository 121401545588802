import Repository from "./repository";

const resource = "riskLevel";

export default {
  async add(payload, table) {
    let endpoint = `${resource}`;
    if (table != "RiskLevel") {
      endpoint = `${endpoint}/${table}`;
    }
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async update(payload, table) {
    let endpoint = `${resource}`;
    if (table != "RiskLevel") {
      endpoint = `${endpoint}/${table}`;
    }
    let response = await Repository.put(endpoint, payload);
    return response;
  },
  async updateStatus(id, status, table) {
    let endpoint = `${resource}`;
    if (table === "RiskLevel") {
      endpoint = `${endpoint}/status`;
    } else {
      endpoint = `${endpoint}/${table}-status`;
    }
    endpoint = `${endpoint}?id=${id}&status=${status}`;
    let response = await Repository.put(endpoint);
    return response;
  },
  async get({ id }) {
    let endpoint = `${resource}/${id}`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async getTable(table) {
    let endpoint = `${resource}`;
    if (table != "RiskLevel") {
      endpoint = `${endpoint}/${table}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async addFinal(payload) {
    let endpoint = `${resource}/Final`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async getFinalRisk({ page, take, search, enabled, filters }) {
    let endpoint = `${resource}/FinalRisk?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (enabled !== undefined && enabled !== null) {
      endpoint += `&enabled=${enabled}`;
    }
    if (filters !== undefined && filters !== null) {
      endpoint = this.setFilters(filters, endpoint);
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async recalculateFinal() {
    let endpoint = `${resource}/FinalRecalculate`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async finalRecalculateTemp(payload) {
    let endpoint = `${resource}/FinalRecalculateTemp`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async getFinalReportExcel({ page, take, search, enabled, filters, contentType }) {
    let endpoint = `${resource}/FinalReportExcel?page=${page}&take=${take}&contentType=${contentType}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (enabled !== undefined && enabled !== null) {
      endpoint += `&enabled=${enabled}`;
    }
    if (filters !== undefined && filters !== null) {
      endpoint = this.setFilters(filters, endpoint);
    }
    let response = await Repository.getBlob(endpoint);
    return response;
  },
  async getHistory({ page, take, search, tableName }) {
    let endpoint = `${resource}/History?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (tableName !== undefined && tableName !== null) {
      endpoint += `&tableName=${tableName}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async usersCreateFinal() {
    let endpoint = `${resource}/UsersCreateFinal`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async templateExcel() {
    let endpoint = `${resource}/GenerateTemplateExcel`;
    let response = await Repository.getBlob(endpoint);
    return response;
  },
  async importExcel(payload) {
    Repository.headers = {
      ...Repository.headers,
      "Content-Type": "multipart/form-data",
    };
    let endpoint = `${resource}/ImportTemplateExcel`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async calculationPercentage() {
    let endpoint = `${resource}/calculationpercentage`;
    let response = await Repository.get(endpoint);
    return response;
  },
  setFilters(filters, endpoint) {
    if (
      filters.registrationDateSocietyStart !== undefined &&
      filters.registrationDateSocietyStart != null &&
      filters.registrationDateSocietyStart != "Invalid date"
    ) {
      endpoint += `&registrationDateSocietyStart=${filters.registrationDateSocietyStart}`;
    }
    if (
      filters.registrationDateSocietyEnd !== undefined &&
      filters.registrationDateSocietyEnd != null &&
      filters.registrationDateSocietyEnd != "Invalid date"
    ) {
      endpoint += `&registrationDateSocietyEnd=${filters.registrationDateSocietyEnd}`;
    }
    if (
      filters.riskEconomicActivity !== undefined &&
      filters.riskEconomicActivity != null
    ) {
      endpoint += `&riskEconomicActivity=${filters.riskEconomicActivity}`;
    }
    if (
      filters.forEconomicActivity !== undefined &&
      filters.forEconomicActivity != null
    ) {
      endpoint += `&forEconomicActivity=${filters.forEconomicActivity}`;
    }
    if (filters.residentAgent !== undefined && filters.residentAgent != null) {
      endpoint += `&residentAgent=${filters.residentAgent}`;
    }
    if (
      filters.antiquitySociety !== undefined &&
      filters.antiquitySociety != null
    ) {
      endpoint += `&antiquitySociety=${filters.antiquitySociety}`;
    }
    if (filters.riskType !== undefined && filters.riskType != null) {
      endpoint += `&riskType=${filters.riskType}`;
    }
    if (filters.societyType !== undefined && filters.societyType != null) {
      endpoint += `&societyType=${filters.societyType}`;
    }
    if (filters.riskCategory !== undefined && filters.riskCategory != null) {
      endpoint += `&riskCategory=${filters.riskCategory}`;
    }
    if (
      filters.categoryLegalEntity !== undefined &&
      filters.categoryLegalEntity != null
    ) {
      endpoint += `&categoryLegalEntity=${filters.categoryLegalEntity}`;
    }
    if (filters.riskCountry !== undefined && filters.riskCountry != null) {
      endpoint += `&riskCountry=${filters.riskCountry}`;
    }
    if (filters.forCountry !== undefined && filters.forCountry != null) {
      endpoint += `&forCountry=${filters.forCountry}`;
    }
    if (filters.taxHaven !== undefined && filters.taxHaven != null) {
      endpoint += `&taxHaven=${filters.taxHaven}`;
    }
    if (
      filters.countryTaxHaven !== undefined &&
      filters.countryTaxHaven != null
    ) {
      endpoint += `&countryTaxHaven=${filters.countryTaxHaven}`;
    }
    if (filters.lawyerFirm !== undefined && filters.lawyerFirm != null) {
      endpoint += `&lawyerFirm=${filters.lawyerFirm}`;
    }
    if (filters.legalEntity !== undefined && filters.legalEntity != null) {
      endpoint += `&legalEntity=${filters.legalEntity}`;
    }
    if (filters.folioNumber !== undefined && filters.folioNumber != null) {
      endpoint += `&folioNumber=${filters.folioNumber}`;
    }
    if (filters.userId !== undefined && filters.userId != null) {
      endpoint += `&userId=${filters.userId}`;
    }

    return endpoint;
  },
};
