import moment from "moment";

export function calculateTimeDifference(date, num, typeTime) {
  let period = addPeriod(date, num, typeTime);
  const currentDate = moment.utc().local();
  const targetDate = period;

  let result = null;
  switch (typeTime) {
    case 0:
      result = targetDate.diff(currentDate, "years");
      break;
    case 1:
      result = targetDate.diff(currentDate, "months");
      break;
    case 2:
      result = targetDate.diff(currentDate, "days");
      break;
    case 3:
      result = targetDate.diff(currentDate, "hours");
      break;
    case 4:
      result = targetDate.diff(currentDate, "minutes");
      break;
    case 5:
      result = targetDate.diff(currentDate, "seconds");
      break;
  }

  return result;
}

export function addPeriod(date, num, typeTime) {
  var duration = null;
  var periodCurrentDate = moment.utc(date).local();

  switch (typeTime) {
    case 0:
      duration = moment.duration(num, "years");
      break;
    case 1:
      duration = moment.duration(num, "months");
      break;
    case 2:
      duration = moment.duration(num, "days");
      break;
    case 3:
      duration = moment.duration(num, "hours");
      break;
    case 4:
      duration = moment.duration(num, "minutes");
      break;
    case 5:
      duration = moment.duration(num, "seconds");
      break;
  }

  periodCurrentDate.add(duration).toDate();

  return periodCurrentDate;
}

export function dynamicMessage(
  message,
  date,
  countLegalEntities,
  expirationPeriod,
  differenceDateSent,
  expirationPeriodType,
  folios
) {
  return message
    .replace("[[AmountOfSocieties]]", countLegalEntities)
    .replace("[[ExpirationPeriod]]", expirationPeriod)
    .replace(
      "[[DateSent]]",
      moment.utc(date).local().format("DD-MM-YYYY hh:mm A")
    )
    .replace(
      "[[DifferenceDateSent]]",
      `${differenceDateSent} ${expirationPeriodType}`
    )
    .replace("[[Folios]]", folios);
}
