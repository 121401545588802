import { RepositoryFactory } from "@/services/api";
import store from '@/store';
import Vue from 'vue';
import VueRouter from 'vue-router';

// OIDC
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import Callback from '../views/oidc/Callback.vue';
import Logout from "../views/oidc/Logout.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: "dashboard",
        component: () => import('../components/Dashboard.vue'),
    },
    {
        path: '/legal-entities',
        name: "legal-entities",
        component: () => import('../components/legal-entities/LegalEntitiesList.vue'),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowResidentAgentAdminFinalBeneficiaries"] || store.getters["application/getEnabledCriteria"];
          next(allow);
        }
    },
    {
        path: '/new-entity',
        name: "new-legal-entity",
        component: () => import('../components/legal-entities/NewLegalEntity.vue'),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowResidentAgentAdminFinalBeneficiaries"] || store.getters["application/getEnabledCriteria"];
          next(allow);
        }
    },
    {
        path: '/legal-entity/:id',
        name: "legal-entity",
        component: () => import('../components/legal-entity-details/LegalEntityDetails.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowResidentAgentAdminFinalBeneficiaries"] || store.getters["application/getEnabledCriteria"];
          next(allow);
        }
    },
    {
        path: '/procedures/start',
        name: "procedures-start",
        component: () => import('../components/procedures-list/ProcedureList.vue'),
    },
    {
        path: '/procedures',
        name: "procedures",
        component: () => import('../views/Procedures.vue'),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceLastProcedureValidation"] || store.getters["application/allowSuperintendenceFirstProcedureValidation"] || store.getters["application/allowOfficialStartProcedure"];
          next(allow);
        }
    },
    {
        path: '/procedure/final-beneficiary/:id',
        name: "procedure",
        component: () => import('../components/procedure/Procedure.vue'),
        props: true,
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceLastProcedureValidation"] || store.getters["application/allowSuperintendenceFirstProcedureValidation"] || store.getters["application/allowOfficialStartProcedure"];
          next(allow);
        }
    },
    {
        path: '/final-beneficiary-request-start',
        name: "final-beneficiary-request-start",
        component: () => import('../components/procedures-forms/FinalBeneficiaryRequestStart.vue'),
    },
    {
        path: '/notifications',
        name: "notifications",
        component: () => import('../components/notifications/NotificationList.vue'),
    },
    {
        path: '/resident-agent-registry/:id',
        name: "resident-agent-registry",
        component: () => import('../components/resident-agent-registry/RegisterResidentAgent.vue'),
        props: true,
        meta: { noNavbar: true }
    },
    {
        path: '/send-resident-agent-invitation',
        name: "send-resident-agent-invitation",
        component: () => import('../components/resident-agent-registry/GenerateInvitation.vue'),
    },
    {
        path: '/resident-agent-profile',
        name: "resident-agent-profile",
        component: () => import('../components/resident-agent-profile/Profile.vue'),
    },
    {
        path: '/resident-agents',
        name: "resident-agent-list",
        component: () => import('../components/resident-agent-list/Roles.vue'),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateRA"] || store.getters["application/allowResidentAgentCreateRoles"];
          next(allow);
        }
    },
    {
        path: '/resident-agent/:id',
        name: "resident-agent",
        component: () => import('../components/resident-agent/ResidentAgent.vue'),
        props: true
    },
    {
        path: '/request-superintendece',
        name: "superintendencia-requests",
        component: () => import('../components/superintendencia-requests/Requests.vue'),
    },
    {
        path: "/superintendence/:id",
        name: 'superintendence',
        component: () => import("../views/Superintendence.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceAsignRoles"];
          next(allow);
        }
    },
    {
        path: "/superintendences",
        name: "superintendences",
        component: () => import('../components/superintendence/Superintendences.vue'),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceAsignRoles"];
          next(allow);
        }
    },
    {
        path: "/send-superintendence-invitation",
        name: 'send-superintendence-invitation',
        component: () => import("../components/superintendence-register/SendSuperintendenceRegister.vue"),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/isSuperintendence"] && store.getters["application/isSuperUser"];
          next(allow);
        }
    },
    {
        path: "/superintendence-registry/:id",
        name: "superintendence-registry",
        component: () => import("../components/superintendence-register/RegisterSuperintendence.vue"),
        props: true,
        meta: { noNavbar: true }
    },
    {
        path: "/competent-authorities",
        name: "competent-authorities",
        component: () => import("../components/competent-authorities/CompetentAuthorities.vue"),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateCA"];
          next(allow);
        }
    },
    {
        path: "/add-competent-authority",
        name: 'add-competent-authority',
        component: () => import("../components/competent-authorities/AddCompetentAuthority.vue"),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateCA"];
          next(allow);
        }
    },
    {
        path: "/competent-authority/:id",
        name: "competent-authority",
        component: () => import("../components/competent-authorities/CompetentAuthority.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateCA"];
          next(allow);
        }
    },
    {
        path: "/officials",
        name: "officials",
        component: () => import("../components/officials/Officials.vue"),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateCA"] || store.getters["application/allowOfficialCreateAndAsignRoles"];
          next(allow);
        }
    },
    {
        path: '/send-official-invitation',
        name: 'send-official-invitation',
        component: () => import("../components/officials-register/SendOfficialInvitation.vue")
    },
    {
        path: '/official-registry/:id',
        name: 'official-registry',
        component: () => import("../components/officials-register/RegisterOfficial.vue"),
        props: true,
        meta: { noNavbar: true }
    },
    {
        path: '/official/:id',
        name: 'official',
        component: () => import("../components/official/Official.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateCA"] || store.getters["application/allowOfficialCreateAndAsignRoles"];
          next(allow);
        }
    },
    {
        path: "/law-firms",
        name: 'law-firms',
        component: () => import("../components/law-firms/LawFirms.vue"),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateRA"] || store.getters["application/allowSuperintendenceReadLawyersFirms"] || store.getters["application/allowSuperintendenceCurActivation"] || store.getters["application/allowSuperintendenceCurDeactivation"];
          next(allow);
        }
    },
    {
        path: "/add-law-firm",
        name: "add-law-firm",
        component: () => import("../components/law-firms/AddLawFirm.vue"),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateRA"];
          next(allow);
        }
    },
    {
        path: "/law-firm/:id",
        name: "law-firm",
        component: () => import("../components/law-firm/LawFirm.vue"),
        props: true,
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceCreateRA"] || store.getters["application/allowSuperintendenceCurActivation"] || store.getters["application/allowSuperintendenceCurDeactivation"];
          next(allow);
        }
    },
    {
        path: '/not-valid-user',
        name: "not-valid-user",
        component: () => import("../views/NotValidUser.vue"),
        meta: { noNavbar: true }
    },
    {
        path: "/events",
        name: 'events',
        component: () => import('../components/events/Events.vue'),
        beforeEnter: (to, from, next) => {
          const allow = store.getters["application/allowSuperintendenceLogs"];
          next(allow);
        }
    },
    {
        path: "/variable-information",
        name: 'variable-information',
        component: () => import('../components/variable-information-reports/VariableInformationReports.vue'),
        beforeEnter: (to, from, next) => {
          next(true);
        }
    },
    {
        path: "/review-request/:id",
        name: 'review-request',
        component: () => import("../components/cur-management/ReviewRequest.vue")
    },
    {
        path: "/risk-management",
        name: 'risk-management',
        component: () => import("../components/risk-management/RiskManagement.vue")
    },
    {
        path: "/alerts",
        name: 'alerts',
        component: () => import("../components/alerts/Alerts.vue")
    },
    {
        path: '/alerts-resident-agent',
        name: "alerts-resident-agent",
        component: () => import('../components/alerts/resident-agent/AlertResidentAgent.vue'),
    },
    // OIDC
    {
        path: '/oidc-callback',
        name: 'oidcCallback',
        component: Callback,
        meta: { isPublic: true, noNavbar: true }
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { noNavbar: true }
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: () => import('../components/ResourceNotFound.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

router.afterEach((route) => {
    RepositoryFactory.event.add({
        code: 2006,
        details: `module-${route.name}`
    });    
})

export default router
