import Repository from "./repository";

const resource = "residentAgent"

export default {
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getByUser() {
        let endpoint = `${resource}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getUsers(type, lawFirm) {
        let endpoint = `${resource}/users?`;

        // if (type !== null && type !== undefined) {
        //     endpoint = `${endpoint}&type=${type}`;
        // }

        if (lawFirm !== null && lawFirm !== undefined) {
            endpoint = `${endpoint}&lawFirm=${lawFirm}`;
        }

        let response = await Repository.get(endpoint);
        return response;
    },
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async update(id, payload) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async getPaged({ lawFirmId, page, take, search, status }) {
        let endpoint = `${resource}/paged?page=${page}&take=${take}`;
        if (lawFirmId !== undefined) {
            endpoint += `&lawFirmId=${lawFirmId}`;
        }
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        if (status !== undefined && status !== null) {
            endpoint += `&status=${status}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getInvitation({ id }) {
        let endpoint = `${resource}/invitation/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async sendInvitation(payload) {
        let endpoint = `${resource}/invitation`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getPagedInvitations({ page, take, search }) {
        let endpoint = `${resource}/invitations?page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async disable(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/disable`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async updatePermissions(payload) {
        let endpoint = `${resource}/roles`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async getEnabledCriteria() {
        let endpoint = `${resource}/enabledCriteria`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async addReasonDisable(payload) {
        let endpoint = `${resource}/reasonDisable`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async updateReasonDisable(payload) {
        let endpoint = `${resource}/reasonDisable`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async getReasonDisablePaged({ page, take, search }) {
        let endpoint = `${resource}/reasonDisable/paged?page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getReasonDisableAll() {
        let endpoint = `${resource}/reasonDisableAll`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async available({ id }) {
        let endpoint = `${resource}/available/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
}