import Repository from "./repository"

const resource = "competentAuthority"

export default {
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getPaged({ page, take, search }) {
        let endpoint = `${resource}/paged?page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getAll() {
        let endpoint = `${resource}/list`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getInvitation({ id }) {
        let endpoint = `${resource}/invitation/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async sendInvitation(payload) {
        let endpoint = `${resource}/invitation`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
}