import Repository from "./repository";

const resource = "superintendence"

export default {
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getByUser() {
        let endpoint = `${resource}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPaged({ page, take, search, status }) {
        let endpoint = `${resource}/paged?page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        if (status !== undefined && status !== null) {
            endpoint += `&status=${status}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getInvitation({ id }) {
        let endpoint = `${resource}/invitation/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async sendInvitation(payload) {
        let endpoint = `${resource}/invitation`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async updatePermissions(payload) {
        let endpoint = `${resource}/roles`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async disable({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
}