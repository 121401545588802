import Repository from "./repository";

const resource = "procedure";

export default {
    async startFinalBeneficiary(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/final-beneficiary/start`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getPagedFinalBeneficiaryProcedures({ page, take, type, search }) {
        let endpoint = `${resource}/final-beneficiary/paged?type=${type}&page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getFinalBeneficiaryProcedure(id) {
        let endpoint = `${resource}/final-beneficiary/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async approveFinalBeneficiaryProcedure(payload) {
        let endpoint = `${resource}/final-beneficiary/approve`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async rejectFinalBeneficiaryProcedure(payload) {
        let endpoint = `${resource}/final-beneficiary/reject`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getFinalBeneficiaryProcedureAttachment(id) {
        let endpoint = `${resource}/final-beneficiary/attachment/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    }
}