import Repository from "./repository";

const resource = "lawFirm";

export default {
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async get({ id }) {
        let endpoint = `${resource}/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async updateCurStatus(payload) {
        let endpoint = `${resource}/cur-status`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async getPaged({ page, take, search, status }) {
        let endpoint = `${resource}/paged?page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        if (status !== undefined && status !== null) {
            endpoint += `&status=${status}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getAll(params) {
        let endpoint = `${resource}/list`;
        if (params) {
            endpoint = `${endpoint}?search=${params.search}&status=${params.status}`
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async requestActivationInactivation(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/request-activation-inactivation`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getRequestActivationInactivation({ id }) {
        let endpoint = `${resource}/request-activation-inactivation/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getPagedRequestActivationInactivation({ page, take, filter, search, requestStatus, criteriaStatus, lawFirmId }) {
        let endpoint = `${resource}/request-activation-inactivation-paged?page=${page}&take=${take}`;
        endpoint = await this.getFilter(endpoint, filter, search, requestStatus, criteriaStatus, lawFirmId);
        let response = await Repository.get(endpoint);
        return response;
    },
    async getRequestActivationInactivations({ filter, search, requestStatus, criteriaStatus, lawFirmId, contentType }) {
        let endpoint = `${resource}/RequestActivationInactivations?contentType=${contentType}`;
        endpoint = await this.getFilter(endpoint, filter, search, requestStatus, criteriaStatus, lawFirmId);
        let response = await Repository.getBlob(endpoint);
        return response;
    },
    async getDocument({ id, document }) {
        let endpoint = `${resource}/documentRequestActivationInactivation/${id}/${document}`;
        let response = await Repository.getBlob(endpoint);
        return response;
    },
    async getDocumentById({ id, document }) {
        let endpoint = `${resource}/documentById/${id}/${document}`;
        let response = await Repository.getBlob(endpoint);
        return response;
    },
    async processRequestActivationInactivation(payload) {
        let endpoint = `${resource}/processRequestActivationInactivation`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getCriteriaInactives({ id }) {
        let endpoint = `${resource}/request-inactivation-by-lawfirm/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async requestActivationInactivationJustification(payload) {
        Repository.headers = {
            ...Repository.headers,
            'Content-Type': 'multipart/form-data'
        };
        let endpoint = `${resource}/request-activation-inactivation-justification`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async getFilter(endpoint, filter, search, requestStatus, criteriaStatus, lawFirmId) {        
        if (filter !== undefined && filter !== null) {
            if (filter.startDate !== undefined && filter.startDate !== null && filter.startDate !== "Invalid date") {
                endpoint += `&startDate=${filter.startDate}`;
            }
            if (filter.endDate !== undefined && filter.endDate !== null && filter.endDate !== "Invalid date") {
                endpoint += `&endDate=${filter.endDate}`;
            }
            if (filter.type !== undefined && filter.type !== null) {
                endpoint += `&type=${filter.type}`;
            }
            if (filter.criteria !== undefined && filter.criteria !== null) {
                endpoint += `&criterias=${filter.criteria}`;
            }
        }
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        if (requestStatus !== undefined && requestStatus !== null) {
            endpoint += `&requestStatus=${requestStatus}`;
        }
        if (criteriaStatus !== undefined && criteriaStatus !== null) {
            endpoint += `&criteriaStatus=${criteriaStatus}`;
        }
        if (lawFirmId !== undefined && lawFirmId !== null) {
            endpoint += `&lawFirmId=${lawFirmId}`;
        }

        return endpoint;
    },
    async updateFirm(payload) {
        let endpoint = `${resource}/update-firm`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
}