import Repository from "./repository";

const resource = "alert";

export default {
  async addDynamic(payload) {
    let endpoint = `${resource}/dynamic`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async updateDynamic(payload) {
    let endpoint = `${resource}/dynamic`;
    let response = await Repository.put(endpoint, payload);
    return response;
  },
  async updateDynamicActive(payload) {
    let endpoint = `${resource}/dynamic-active`;
    let response = await Repository.put(endpoint, payload);
    return response;
  },
  async getPagedDynamic({ page, take, search, createDate, status, superintendence }) {
    let endpoint = `${resource}/dynamic/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (superintendence !== undefined && superintendence !== null) {
      endpoint += `&superintendence=${superintendence}`;
    }
    if (createDate !== undefined && createDate !== null) {
      endpoint += `&createDate=${createDate}`;
    }
    if (status !== undefined && status !== null) {
      endpoint += `&status=${status}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async getAllDynamic() {
    let endpoint = `${resource}/dynamic/all`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async getLawFirms({
    page,
    take,
    search,
    status,
    fromCountLegalEntities,
    toCountLegalEntities,
    fromCountLegalEntitiesRp,
    toCountLegalEntitiesRp,
    type,
  }) {
    let endpoint = `${resource}/lawFirms/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (status !== undefined && status !== null) {
      endpoint += `&status=${status}`;
    }
    if (
      fromCountLegalEntities !== undefined &&
      fromCountLegalEntities !== null
    ) {
      endpoint += `&fromCountLegalEntities=${fromCountLegalEntities}`;
    }
    if (toCountLegalEntities !== undefined && toCountLegalEntities !== null) {
      endpoint += `&toCountLegalEntities=${toCountLegalEntities}`;
    }
    if (
      fromCountLegalEntitiesRp !== undefined &&
      fromCountLegalEntitiesRp !== null
    ) {
      endpoint += `&fromCountLegalEntitiesRp=${fromCountLegalEntitiesRp}`;
    }
    if (
      toCountLegalEntitiesRp !== undefined &&
      toCountLegalEntitiesRp !== null
    ) {
      endpoint += `&toCountLegalEntitiesRp=${toCountLegalEntitiesRp}`;
    }
    if (type !== undefined && type !== null) {
      endpoint += `&lawFirmType=${type}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async saveManualNotification(payload) {
    let endpoint = `${resource}/manual-send`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async saveDynamicNotification(payload) {
    let endpoint = `${resource}/dynamic-send`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async getDynamicSent({ page, take, search, type, state }) {
    let endpoint = `${resource}/dynamicNotification/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (type !== undefined && type !== null) {
      endpoint += `&type=${type}`;
    }
    if (state !== undefined && state !== null) {
      endpoint += `&state=${state}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async getDynamicNotificationLawFirms(id) {
    let endpoint = `${resource}/dynamicNotificationLawFirms/${id}`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async manualNotificationForLawFirm() {
    let endpoint = `${resource}/manualNotificationForLawFirm`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async dynamicNotificationForLawFirm() {
    let endpoint = `${resource}/dynamicNotificationForLawFirm`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async automaticNotificationForLawFirm() {
    let endpoint = `${resource}/automaticNotificationForLawFirm`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async updateManualNotificationLawFirmStatus(payload) {
    let endpoint = `${resource}/updateManualNotificationLawFirmStatus`;
    let response = await Repository.put(endpoint, payload);
    return response;
  },
  async updateDynamicNotificationLawFirmStatus(payload) {
    let endpoint = `${resource}/updateDynamicNotificationLawFirmStatus`;
    let response = await Repository.put(endpoint, payload);
    return response;
  },
  async updateAutomaticNotificationLawFirmStatus(payload) {
    let endpoint = `${resource}/updateAutomaticNotificationLawFirmStatus`;
    let response = await Repository.put(endpoint, payload);
    return response;
  },
  async getDynamicSentStatus({
    page,
    take,
    search,
    type,
    needConfirmation,
    expired,
    status,
    startDate,
    endDate,
    isResidentAgent,
    superintendence
  }) {
    let endpoint = `${resource}/dynamicNotificationLawFirms/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (type !== undefined && type !== null) {
      endpoint += `&type=${type}`;
    }
    if (needConfirmation !== undefined && needConfirmation !== null) {
      endpoint += `&needConfirmation=${needConfirmation}`;
    }
    if (expired !== undefined && expired !== null) {
      endpoint += `&expired=${expired}`;
    }
    if (status !== undefined && status !== null) {
      endpoint += `&status=${status}`;
    }
    if (startDate !== undefined && startDate !== null) {
      endpoint += `&startDate=${startDate}`;
    }
    if (endDate !== undefined && endDate !== null) {
      endpoint += `&endDate=${endDate}`;
    }
    if (isResidentAgent !== undefined && isResidentAgent !== null) {
      endpoint += `&isResidentAgent=${isResidentAgent}`;
    }
    if (superintendence !== undefined && superintendence !== null) {
      endpoint += `&superintendence=${superintendence}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async getManualSentStatus({
    page,
    take,
    search,
    status,
    startDate,
    endDate,
    isResidentAgent,
    superintendence
  }) {
    let endpoint = `${resource}/manualNotificationLawFirms/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (status !== undefined && status !== null) {
      endpoint += `&status=${status}`;
    }
    if (startDate !== undefined && startDate !== null) {
      endpoint += `&startDate=${startDate}`;
    }
    if (endDate !== undefined && endDate !== null) {
      endpoint += `&endDate=${endDate}`;
    }
    if (isResidentAgent !== undefined && isResidentAgent !== null) {
      endpoint += `&isResidentAgent=${isResidentAgent}`;
    }
    if (superintendence !== undefined && superintendence !== null) {
      endpoint += `&superintendence=${superintendence}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async getPagedAutomatic({ page, take, search, status }) {
    let endpoint = `${resource}/automatic/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (status !== undefined && status !== null) {
      endpoint += `&status=${status}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async addAutomatic(payload) {
    let endpoint = `${resource}/automatic`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async updateAutomatic(payload) {
    let endpoint = `${resource}/automatic`;
    let response = await Repository.put(endpoint, payload);
    return response;
  },
  async getAutomaticSentStatus({
    page,
    take,
    search,
    type,
    status,
    startDate,
    endDate,
    isResidentAgent,
  }) {
    let endpoint = `${resource}/automaticNotificationLawFirms/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (type !== undefined && type !== null) {
      endpoint += `&type=${type}`;
    }
    if (status !== undefined && status !== null) {
      endpoint += `&status=${status}`;
    }
    if (startDate !== undefined && startDate !== null) {
      endpoint += `&startDate=${startDate}`;
    }
    if (endDate !== undefined && endDate !== null) {
      endpoint += `&endDate=${endDate}`;
    }
    if (isResidentAgent !== undefined && isResidentAgent !== null) {
      endpoint += `&isResidentAgent=${isResidentAgent}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async addLegalEntityGroup(payload) {
    let endpoint = `${resource}/legalEntityGroup`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async updateLegalEntityGroup(payload) {
    let endpoint = `${resource}/legalEntityGroup`;
    let response = await Repository.put(endpoint, payload);
    return response;
  },
  async getPagedLegalEntityGroup({ page, take, search, active }) {
    let endpoint = `${resource}/legalEntityGroup/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (active !== undefined && active !== null) {
      endpoint += `&active=${active}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
  async saveNumberLegalEntityInPublicRegistry(payload) {
    let endpoint = `${resource}/numberLegalEntityInPublicRegistry`;
    let response = await Repository.post(endpoint, payload);
    return response;
  },
  async getLawFirmByFolio(societyType, folio) {
    let endpoint = `${resource}/lawFirmByFolio/${societyType}/${folio}`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async getSuperintendences() {
    let endpoint = `${resource}/superintendences`;
    let response = await Repository.get(endpoint);
    return response;
  },
  async getJoinSentStatus({
    page,
    take,
    search,
    status,
    startDate,
    endDate,
  }) {
    let endpoint = `${resource}/JoinNotificationLawFirms/paged?page=${page}&take=${take}`;
    if (search !== undefined && search !== null) {
      endpoint += `&search=${search}`;
    }
    if (status !== undefined && status !== null) {
      endpoint += `&status=${status}`;
    }
    if (startDate !== undefined && startDate !== null) {
      endpoint += `&startDate=${startDate}`;
    }
    if (endDate !== undefined && endDate !== null) {
      endpoint += `&endDate=${endDate}`;
    }
    let response = await Repository.get(endpoint);
    return response;
  },
};
