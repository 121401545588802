import Repository from "./repository"

const resource = "stadistics";

export default {
    async setCurrentLoad(payload) {
        let endpoint = `${resource}/superintendence/current-load`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async getSuperintendenceCounters() {
        let endpoint = `${resource}/superintendence/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getLegalEntitiesByLawFirm() {
        let endpoint = `${resource}/superintendence/lawFirm/legalEntities`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getLawFirmsReport() {
        let endpoint = `${resource}/superintendence/lawFirm/legalEntities/report`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getLawFirmsDayReport() {
        let endpoint = `${resource}/superintendence/lawFirm/legalEntities/day/report`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getLegalEntitiesByCountryReport() {
        let endpoint = `${resource}/superintendence/legalEntities/country/report`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getResidentAgentStadistics() {
        let endpoint = `${resource}/resident-agent`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getResidentAgentSummary() {
        let endpoint = `${resource}/resident-agent/summary`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceResidentAgentInProduction() {
        let endpoint = `${resource}/superintendence/resident-agent-in-prod/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceResidentAgentRegistered() {
        let endpoint = `${resource}/superintendence/resident-agent-registered/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceResidentAgentNotified() {
        let endpoint = `${resource}/superintendence/resident-agent-notified/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceSummaryLoad() {
        let endpoint = `${resource}/superintendence/summary-load/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendencePJSummaryLoad() {
        let endpoint = `${resource}/superintendence/summary/pj`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getAllSuperintendenceTopologyLoad() {
        let endpoint = `${resource}/superintendence/summary/pj-topologies`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceLoad() {
        let endpoint = `${resource}/superintendence/monthly-load/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceProceduresLoad() {
        let endpoint = `${resource}/superintendence/procedure/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceProcedureDetailsLoad() {
        let endpoint = `${resource}/superintendence/procedure/details`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceAllPJRequestedProceduresLoad() {
        let endpoint = `${resource}/superintendence/procedure/pj-counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceAllCompletedProceduresLoad() {
        let endpoint = `${resource}/superintendence/procedure/completed-counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceFoliosProceduresLoad() {
        let endpoint = `${resource}/superintendence/procedure/folios`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceFoliosRequestsProceduresLoad() {
        let endpoint = `${resource}/superintendence/procedure/folios-requests`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceAttentionLoad() {
        let endpoint = `${resource}/superintendence/procedure/attention`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceRequestTimeLoad() {
        let endpoint = `${resource}/superintendence/procedure/requests-time`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceCompetentAuthorityLoad() {
        let endpoint = `${resource}/superintendence/procedure/competent-authority`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceAllPendingProceduresLoad() {
        let endpoint = `${resource}/superintendence/procedure/pending-counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSuperintendenceAllCancelledProceduresLoad() {
        let endpoint = `${resource}/superintendence/procedure/cancelled-counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getRiskHightSocietiesLoad() {
        let endpoint = `${resource}/superintendence/risk/hight-societies`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getRiskHightActivitiesLoad() {
        let endpoint = `${resource}/superintendence/risk/activities`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getRiskHightJurisdictionLoad() {
        let endpoint = `${resource}/superintendence/risk/jurisdictions`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getRiskARHightSocietiesLoad() {
        let endpoint = `${resource}/superintendence/risk/hight-resident-agents`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getTotalCountriesWithMoreSocietiesLoad() {
        let endpoint = `${resource}/superintendence/risk/countries-societies`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getRiskHighLevelsLoad() {
        let endpoint = `${resource}/superintendence/risk/hight-levels`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getRiskInOutPanamaLoad() {
        let endpoint = `${resource}/superintendence/risk/in-out-panama`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getPJInPanamaLoad() {
        let endpoint = `${resource}/superintendence/pj/in-panama`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getPJOutPanamaLoad() {
        let endpoint = `${resource}/superintendence/pj/out-panama`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getHightCountriesLoad() {
        let endpoint = `${resource}/superintendence/risk/hight-countries`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getPJClasificationLoad() {
        let endpoint = `${resource}/superintendence/procedure/pj-clasification`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getPJProccecedLoad() {
        let endpoint = `${resource}/superintendence/procedure/pj-procceced`;
        let response = await Repository.get(endpoint);

        return response;
    },
    async getResidetAgentPJSummaryLoad() {
        let endpoint = `${resource}/resident-agent/summary/pj`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getResidetAgentBFSummaryLoad() {
        let endpoint = `${resource}/resident-agent/summary/bf`;
        let response = await Repository.get(endpoint);
        return response;
    },
    // async getResidetAgentTopologyLoad() {
    //     let endpoint = `${resource}/resident-agent/summary/pj-topologies`;
    //     let response = await Repository.get(endpoint);
    //     return response;
    // },
    async getResidetAgentMontlhyLoad() {
        let endpoint = `${resource}/resident-agent/monthly-load/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getOfficialProceduresLoad() {
        let endpoint = `${resource}/official/procedure/counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getOfficialPJProceduresLoad() {
        let endpoint = `${resource}/official/procedure/pj-counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getOfficialPendingProcedures() {
        let endpoint = `${resource}/official/procedure/pending-counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getOfficialCompletedProcedures() {
        let endpoint = `${resource}/official/procedure/completed-counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getOfficialCancelledProcedures() {
        let endpoint = `${resource}/official/procedure/cancelled-counters`;
        let response = await Repository.get(endpoint);
        return response;
    },
}