<template>
  <div v-if="isResidentAgent">
    <div v-for="(notification, index) in notifications" :key="index">
      <b-modal
        v-model="dynamicNotificationModals[index]"
        content-class="rounded-0"
        dialog-class="long-modal"
        header-class="border-0"
        centered
        hide-header
        hide-footer
        no-close-on-backdrop
      >
        <h6 class="page-title pb-2 mt-3 text-uppercase">
          {{ getType(notification.alertDynamicNotification.type) }}
        </h6>

        <p>{{ getMessage(notification) }}</p>

        <div
          class="text-center mt-2 mb-2"
          v-if="
            notification.alertDynamicNotification.type === 0 &&
            !notification.expired &&
            notification.alertDynamicNotification.needConfirmation
          "
        >
          <b-button
            class="p-3 me-2"
            variant="outline-primary"
            @click="readModal(notification.id, index)"
          >
            <span>Actualizar después</span>
          </b-button>
          <b-button
            class="p-3 me-2"
            variant="outline-primary"
            @click="readToGoModal(notification)"
          >
            <span>Actualizar ahora</span>
          </b-button>
          <b-button
            class="p-3 main-btn"
            @click="confirm(notification.id, index)"
          >
            <span>Ya he actualizado</span>
          </b-button>
        </div>
        <div
          class="text-end"
          v-else-if="notification.alertDynamicNotification.type === 1"
        >
          <b-button
            class="my-4 btn main-btn me-2"
            @click="readModal(notification.id, index)"
          >
            <span>Aceptar</span>
          </b-button>
          <b-button
            class="my-4 btn main-btn"
            @click="doneDisconnected(notification, index)"
            v-if="notification.alertDynamicNotification.needConfirmation"
          >
            <span>Ya los desvincule</span>
          </b-button>
        </div>
        <div class="text-end" v-else>
          <b-button
            class="my-4 btn main-btn"
            @click="notifiedModal(notification.id, index)"
          >
            <span>Aceptar</span>
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { alertDynamicType } from "@/config/alertDynamicType";
import { expirationPeriodTypes } from "@/config/expirationPeriodTypes";
import { societyType } from "@/config/society-type";
import { alertStatus } from "@/config/alertStatus";
import { calculateTimeDifference, dynamicMessage } from "@/services/alerts";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "alert-dynamic-notification-lawfirm",
  props: {
    isResidentAgent: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      alertDynamicType: alertDynamicType,
      expirationPeriodTypes: expirationPeriodTypes,
      societyType: societyType,
      dynamicNotificationModals: [],
      notifications: null,
      statusRead: alertStatus.find((x) => x.label === "Read").key,
      statusConfirme: alertStatus.find((x) => x.label === "Confirmed").key,
      statusNotified: alertStatus.find((x) => x.label === "Notified").key,
      statusDone: alertStatus.find((x) => x.label === "Done").key,
    };
  },
  computed: {
    ...mapGetters("application", ["getModalAlertDynamicNotificationLawFirm"]),
    modalAlertDynamicNotificationLawFirm() {
      return this.getModalAlertDynamicNotificationLawFirm;
    },
  },
  watch: {
    isResidentAgent() {
      this.getNotification();
    },
    modalAlertDynamicNotificationLawFirm(value) {
      this.notifications = [value];
      this.dynamicNotificationModals = new Array(
        this.notifications.length
      ).fill(true);
    },
  },
  methods: {
    ...mapActions("application", ["setAlertDynamicNotificationLawFirm"]),
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    async getNotification() {
      try {
        let response =
          await RepositoryFactory.alertRepository.dynamicNotificationForLawFirm();
        this.notifications = response.data;

        this.dynamicNotificationModals = new Array(
          this.notifications.length
        ).fill(true);

        this.setAlertDynamicNotificationLawFirm(this.notifications);
      } catch (error) {
        console.log(error);
      }
    },
    async updateStatus(id, status) {
      let payload = {
        id: id,
        status: status,
      };
      return await RepositoryFactory.alertRepository.updateDynamicNotificationLawFirmStatus(
        payload
      );
    },
    async readToGoModal(notification) {
      switch (notification.alertDynamicNotification.type) {
        case 0:
          this.dynamicNotificationModals = new Array(
            this.dynamicNotificationModals.length
          ).fill(false);
          await this.updateStatus(notification.id, this.statusRead);
          this.$router.push({
            name: "legal-entities",
          });
          return;
      }
    },
    async readModal(id, index) {
      try {
        await this.updateStatus(id, this.statusRead);
        this.closeModal(index);
      } catch (error) {
        console.log(error);
      }
    },
    async confirm(id, index) {
      try {
        await this.updateStatus(id, this.statusConfirme);
        this.closeModal(index);
        window.location.href = "/";
      } catch (error) {
        console.log(error);
      }
    },
    async doneDisconnected(notification, index) {
      try {
        for (const x of notification.payloadDisconnectedLegalEntities) {
          try {
            const response =
              await RepositoryFactory.alertRepository.getLawFirmByFolio(
                x.societyType,
                x.folio
              );

            if (response.status === 200 && response.data) {
              var folio = `${this.societyTypeDesriptions(
                x.societyType
              )} ${+x.folio}`;
              this.openModal();
              this.setModalInfo({
                title: "Aviso",
                message: `El folio ${folio} aún no lo ha desvinculado`,
              });
              return;
            }
          } catch (error) {
            console.error("Error:", error);
            this.openModal();
            this.setModalInfo({
              title: "Aviso",
              message: "Ha ocurrido un error por favor intente más tarde.",
            });
            return;
          }
        }

        await this.updateStatus(notification.id, this.statusDone);
        this.closeModal(index);
        window.location.href = "/";
      } catch (error) {
        console.log(error);
      }
    },
    async notifiedModal(id, index) {
      try {
        await this.updateStatus(id, this.statusNotified);
        this.closeModal(index);
      } catch (error) {
        console.log(error);
      }
    },
    closeModal(index) {
      this.$set(this.dynamicNotificationModals, index, false);
    },
    getType(value) {
      const t = this.alertDynamicType.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    getExpirationPeriodType(value) {
      const t = this.expirationPeriodTypes.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    societyTypeDesriptions(value) {
      return this.societyType.find((x) => x.value === value).text;
    },
    getMessage(notification) {
      notification.expired = false;
      let message = notification.alertDynamicNotification.message;

      switch (notification.alertDynamicNotification.type) {
        case 0:
        case 1:
          var createdAt = notification.alertDynamicNotification.createdAt;
          var expirationPeriodType = this.getExpirationPeriodType(
            notification.alertDynamicNotification.expirationPeriodType
          );
          var expirationPeriod = `${notification.alertDynamicNotification.expirationPeriod} ${expirationPeriodType}`;
          var differenceDateSent = calculateTimeDifference(
            createdAt,
            notification.alertDynamicNotification.expirationPeriod,
            notification.alertDynamicNotification.expirationPeriodType
          );

          if (
            notification.alertDynamicNotification.type !== 1 &&
            (differenceDateSent < 0 || notification.status === 3)
          ) {
            notification.expired = true;
            message = notification.alertDynamicNotification.expirationMessage;
          } else {
            var folios = "";

            if (notification?.payloadDisconnectedLegalEntities) {
              folios = notification.payloadDisconnectedLegalEntities.map(
                (entity) =>
                  this.societyTypeDesriptions(entity.societyType) +
                  " " +
                  entity.folio
              );
            }

            message = dynamicMessage(
              notification.alertDynamicNotification.message,
              createdAt,
              notification.countLegalEntities,
              expirationPeriod,
              differenceDateSent,
              expirationPeriodType,
              folios
            );
          }

          break;
      }

      return message;
    },
  },
};
</script>
