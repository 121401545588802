import Vue from "vue";

export const legalEntity = {
    namespaced: true,
    state: {
        legalEntity: null,
        finalBeneficiaries: [],
        juridictions:[],
        directorNominee: null,
        directorNominees: [],
        legalRepresentatives: [],
        shareholderNominee: null,
        shareholderNominees: [],
        saveLegalEntity: false
    },
    actions: {
        setLegalEntity({ commit }, legalEntity) {
            commit('setLegalEntity', { legalEntity });
        },
        setFinalBeneficiaries({ commit }, finalBeneficiaries) {
            commit('setFinalBeneficiaries', { finalBeneficiaries });
        },
        setjuridictions({ commit }, juridictions) {
            commit('setjuridictions', { juridictions });
        },
        setDirectorNominees({ commit }, nominees) {
            commit('setDirectorNominees', { nominees })
        },
        setLegalRepresentatives({ commit }, list) {
            commit('setLegalRepresentatives', { list })
        },
        setShareholderNominees({ commit }, nominees) {
            commit('setShareholderNominees', { nominees });
        },
        setDirectorNominee({ commit }, nominee) {
            commit('setDirectorNominee', { nominee })
        },
        setShareholderNominee({ commit }, nominee) {
            commit('setShareholderNominee', { nominee });
        },
        setSaveLegalEntity({ commit }, value) {
            commit('setSaveLegalEntity', { value });
        },
        reset({ commit }) {
            commit('reset');
        }
    },
    mutations: {
        setLegalEntity(state, { legalEntity }) {
            Vue.set(state, 'legalEntity', legalEntity);
        },
        setFinalBeneficiaries(state, { finalBeneficiaries }) {
            Vue.set(state, 'finalBeneficiaries', finalBeneficiaries);
        },
        setDirectorNominees(state, { nominees }) {
            Vue.set(state, 'directorNominees', nominees);
        },
        setLegalRepresentatives(state, { list }) {
            Vue.set(state, 'legalRepresentatives', list);
        },
        setShareholderNominees(state, { nominees }) {
            Vue.set(state, 'shareholderNominees', nominees);
        },
        setDirectorNominee(state, { nominee }) {
            Vue.set(state, 'directorNominee', nominee);
        },
        setShareholderNominee(state, { nominee }) {
            Vue.set(state, 'shareholderNominee', nominee);
        },
        setSaveLegalEntity(state, { value }) {
            Vue.set(state, 'saveLegalEntity', value);
        },
        reset(state) {
            Vue.set(state, 'legalEntity', null);
            Vue.set(state, 'finalBeneficiaries', []);
            Vue.set(state, 'directorNominees', []);
            Vue.set(state, 'legalRepresentatives', []);
            Vue.set(state, 'shareholderNominees', []);
            Vue.set(state, 'directorNominee', null);
            Vue.set(state, 'shareholderNominee', null);
            Vue.set(state, 'saveLegalEntity', false);
        }
    },
    getters: {
        getLegalEntity: state => state.legalEntity,
        getFinalBeneficiaries: state => state.finalBeneficiaries,
        getDirectorNominees: state => state.directorNominees,
        getLegalRepresentatives: state => state.legalRepresentatives,
        getShareholderNominees: state => state.shareholderNominees,
        getDirectorNominee: state => state.directorNominee,
        getShareholderNominee: state => state.shareholderNominee,
        getSaveLegalEntity: state => state.saveLegalEntity
    }
}