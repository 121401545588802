var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showNavbar)?_c('b-navbar',{staticClass:"shadow-sm py-lg-0",attrs:{"toggleable":"lg","type":"light","variant":"default"}},[_c('b-navbar-brand',{staticClass:"nav-title",attrs:{"to":"/"}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("@/assets/icons/Logo-BeneficiarioFinal-V.png"),"alt":"Icon"}}),_c('span',{attrs:{"id":"tooltip-target"}},[_vm._v(" 2.004 "),_c('b-tooltip',{attrs:{"target":"tooltip-target","triggers":"hover"}},[_vm._v(" 2.004240821 ")])],1)]),_c('b-navbar-toggle',{staticClass:"mx-4",attrs:{"target":"nav-collapse"},scopedSlots:_vm._u([{key:"default",fn:function({ expanded }){return [(expanded)?_c('b-icon',{attrs:{"icon":"chevron-bar-up"}}):_c('b-icon',{attrs:{"icon":"chevron-bar-down"}})]}}],null,false,1828389767)}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"portal-nav ms-auto me-xl-5"},[(_vm.showDashboard)?_c('b-nav-item',{staticClass:"nav-menu-text",attrs:{"to":{ name: 'dashboard' }}},[_c('b-icon-graph-up',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(_vm._s(_vm.$t("navbar.dashboard"))+" ")],1):_vm._e(),(_vm.showProcedures)?_c('b-nav-item',{staticClass:"nav-menu-text",class:{
        'router-link-exact-active':
            _vm.$route.name == 'procedure-details' ||
            _vm.$route.name == 'risk-report' ||
            _vm.$route.name ==
            'final-beneficiary-request-start',
    },attrs:{"to":{ name: 'procedures' }}},[_c('b-icon-clipboard-check',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(_vm._s(_vm.$t("navbar.procedures")))],1):_vm._e(),(_vm.showLegalEntities || _vm.enabledCriteria)?_c('b-nav-item',{staticClass:"nav-menu-text",class:{
        'router-link-exact-active':
            _vm.$route.name == 'new-legal-entity' ||
            _vm.$route.name == 'legal-entity',
    },attrs:{"to":{ name: 'legal-entities' }}},[_c('b-icon-people',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(_vm._s(_vm.$t("navbar.legal-persons"))+" ")],1):_vm._e(),(_vm.showSuperintendences)?_c('b-nav-item',{staticClass:"nav-menu-text",class:{
        'router-link-exact-active':
            _vm.$route.name ===
            'send-superintendence-invitation',
    },attrs:{"to":{ name: 'superintendences' }}},[_c('b-icon-briefcase',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(" "+_vm._s(_vm.$t("navbar.superintendence"))+" ")],1):_vm._e(),(_vm.showLawFirms)?_c('b-nav-item',{staticClass:"nav-menu-text",class:{
        'router-link-exact-active':
            _vm.$route.name === 'add-law-firm' ||
            _vm.$route.name === 'law-firm',
    },attrs:{"to":{ name: 'law-firms' }}},[_c('b-icon-people-fill',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(" "+_vm._s(_vm.$t("navbar.law-firms")))],1):_vm._e(),(_vm.showResidentAgents)?_c('b-nav-item',{staticClass:"nav-menu-text",class:{
        'router-link-exact-active':
            _vm.$route.name ===
            'send-resident-agent-invitation',
    },attrs:{"to":{ name: 'resident-agent-list' }}},[_c('b-icon-person',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(" "+_vm._s(_vm.$t("navbar.resident-agents")))],1):_vm._e(),(_vm.showCompetentAuthorities)?_c('b-nav-item',{staticClass:"nav-menu-text",class:{
        'router-link-exact-active':
            _vm.$route.name === 'add-competent-authority' ||
            _vm.$route.name === 'competent-authority',
    },attrs:{"to":{ name: 'competent-authorities' }}},[_c('b-icon-building',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(" "+_vm._s(_vm.$t("navbar.competent-authorities")))],1):_vm._e(),(_vm.showOfficials)?_c('b-nav-item',{staticClass:"nav-menu-text",class:{
        'router-link-exact-active':
            _vm.$route.name === 'send-official-invitation',
    },attrs:{"to":{ name: 'officials' }}},[_c('b-icon-person-square',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(" "+_vm._s(_vm.$t("navbar.officials")))],1):_vm._e(),(_vm.showResidentAgent)?_c('b-nav-item',{staticClass:"nav-menu-text",attrs:{"to":{ name: 'resident-agent-profile' }}},[_c('b-icon-person-circle',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(" "+_vm._s(_vm.$t("navbar.resident-agent"))+" ")],1):_vm._e(),(_vm.showEvents)?_c('b-nav-item-dropdown',{staticClass:"nav-menu-text mt-1",attrs:{"right":""}},[_c('template',{slot:"button-content"},[_c('b-icon-calendar2-event',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_c('br'),_c('span',[_vm._v("Reportería")])],1),_c('b-dropdown-item',{staticClass:"nav-menu-text",attrs:{"to":{ name: 'events' }}},[_c('b-icon-calendar2-event',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(" "+_vm._s(_vm.$t("navbar.events"))+" ")],1),_c('b-dropdown-item',{staticClass:"nav-menu-text",attrs:{"to":{ name: 'variable-information' }}},[_c('b-icon-calendar2-event',{staticClass:"me-2",attrs:{"scale":"0.8"}}),_vm._v(" "+_vm._s(_vm.$t("navbar.variableInformation"))+" ")],1)],2):_vm._e()],1)],1),_c('b-collapse',{attrs:{"id":"main-nav-collapse","is-nav":""}},[_c('Sso')],1)],1):_vm._e(),(_vm.enabledCriteria)?_c('h6',{staticClass:"pb-2 mt-5 text-center text-danger"},[_vm._v(" SU FIRMA HA SIDO INACTIVADA, PARA SOLICITAR ACTIVACIÓN PÓNGASE EN CONTACTO CON UN ADMINISTRADOR DEL SITIO ")]):_vm._e(),_c('AlertManualNotificationLawFirm',{attrs:{"isResidentAgent":_vm.isResidentAgent || _vm.enabledCriteria}}),_c('AlertDynamicNotificationLawFirm',{attrs:{"isResidentAgent":_vm.isResidentAgent || _vm.enabledCriteria}}),_c('AlertAutomaticNotificationLawFirm',{attrs:{"isResidentAgent":_vm.isResidentAgent || _vm.enabledCriteria}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }