<template>
    <div class="mb-5">
        <div v-if="validating">
            <ValidatingUser />
        </div>
        <div v-show="!validating">
            <nav-bar></nav-bar>
            <b-container fluid>
                <slot />
            </b-container>
            <Modals />
        </div>
        <b-modal id="alert-message" v-model="modalAlert" content-class="rounded-0" dialog-class="long-modal"
            header-class="border-0" header-bg-variant="light" title="Aviso de sesión" hide-header-close centered
            no-close-on-backdrop hide-footer>
            <div class="text-center">
                <p class="form-title mt-2">
                    Se terminará la sesión en {{ nTimeout }} segundos ...
                </p>
            </div>
            <b-row class="m-3 text-center">
                <b-col>
                    <b-button size="sm" class="main-btn px-3 ms-4" @click="closeModalDisconnect">Presione aquí para
                        continuar en sesión</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { roles } from "@/config/roles.js";
import { isUserRegistered, isUserRegisteredAndEnabled, isEnabledCriteria } from "@/services/users";

import ValidatingUser from "./ValidatingUser.vue";
import Modals from "./Modals.vue";
import NavBar from "./NavBar";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "LayoutDefault",
    components: {
        NavBar,
        Modals,
        ValidatingUser,
    },
    data() {
        return {
            timer: null,
            validating: true,
            validUser: false,
            timeout: null,
            secondTimeOutDisconnect: 10,
            inactivityTimeout: process.env.VUE_APP_INACTIVITY_TIMER * 60 * 1000,
            modalAlert: false,
            disconnectTimeout: null,
            nTimeout: null
        };
    },
    beforeDestroy() {
        document.removeEventListener('mousemove', this.resetInactivityTimer);
        document.removeEventListener('keypress', this.resetInactivityTimer);
        document.removeEventListener('touchstart', this.resetInactivityTimer);
        clearInterval(this.timer);
        clearTimeout(this.timeout);
    },
    mounted() {
        document.addEventListener('mousemove', this.resetInactivityTimer);
        document.addEventListener('keypress', this.resetInactivityTimer);
        document.addEventListener('touchstart', this.resetInactivityTimer);
        this.startTimer();
        this.nTimeout = this.secondTimeOutDisconnect;
        this.timeout = setTimeout(this.disconnect, this.inactivityTimeout);
    },
    computed: {
        ...mapGetters("oidcStore", ["oidcIsAuthenticated", "oidcUser"]),
    },
    methods: {
        ...mapActions("oidcStore", ["signOutOidc"]),
        resetInactivityTimer() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.disconnect, this.inactivityTimeout);
        },
        closeModalDisconnect() {
            this.modalAlert = false;
            this.nTimeout = this.secondTimeOutDisconnect;
            clearTimeout(this.disconnectTimeout);
            this.startTimer();
        },
        disconnect() {
            if (this.oidcIsAuthenticated) {
                this.modalAlert = true;
                this.disconnectTimeout = setInterval(() => {
                    this.nTimeout--
                    if (this.nTimeout === 0) {
                        this.signOutOidc();
                        this.closeModalDisconnect();
                        RepositoryFactory.event.add({
                            code: 2001,
                            details: this.oidcUser.email
                        });
                    }
                }, 1000);
            }
        },
        async validateUser() {
            if (!this.oidcIsAuthenticated) {
                return;
            }

            let meta = this.$route.meta;
            if (meta.isPublic || meta.noNavbar === true) {
                this.validUser = true;
                this.validating = false;
                this.stopTimer();
                return;
            }

            this.stopTimer();

            if ((this.oidcUser.email !== "superintendence@zertifika.com" && this.oidcUser.email !== "egranado@zertifika.com") && this.oidcUser.idp !== "TwoFactor") {
                RepositoryFactory.event.add({
                    code: 2001,
                    details: this.oidcUser.email
                });
                this.signOutOidc();
            }

            RepositoryFactory.event.add({
                code: 2000,
                details: this.oidcUser.email,
            });

            let userRoles = this.oidcUser.role;
            // ---------- Only check roles ---------
            let isResidentAgent = userRoles.indexOf(roles.residentAgent) > -1;
            let isOfficial = userRoles.indexOf(roles.official) > -1;
            let isSuperintendence =
                userRoles.indexOf(roles.superintendence) > -1;
            var validRoles = isResidentAgent || isOfficial || isSuperintendence;

            if (validRoles) {
                // Check user from db
                if (isResidentAgent) {
                    validRoles = await isUserRegisteredAndEnabled();
                } else {
                    validRoles = await isUserRegistered();
                }
            }

            this.validUser = validRoles;

            this.validating = false;
            console.log(!this.validUser)
            if (!this.validUser) {
                if (isResidentAgent) {
                    var enabledCriteria = await isEnabledCriteria();

                    if (enabledCriteria) {
                        this.redirectToEnabledCriteria();
                        return;
                    }
                }

                this.redirectToWrongUser();
            }
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.validateUser();
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.timer);
        },
        redirectToWrongUser() {
            this.$router.push({
                name: "not-valid-user",
            });
        },
        redirectToEnabledCriteria() {
            this.$router.push({
                name: "legal-entities",
            });
        },
    },
};
</script>

<style>
p {
    font-size: 16px !important;
}

.checkbox-container {
    .custom-checkbox {
        display: inline-block;

        .checkbox-text {
            margin-left: 0.5rem;
        }
    }
}

.cs-pointer {
    cursor: pointer;
}

.main-btn {
    background-color: #456b8f !important;
    border-radius: 0 !important;
}

.main-danger-btn {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    border-radius: 0 !important;
}

.page-title {
    color: #456b8f;
}

.card-title-color {
    color: #406892;
}

.form-title {
    font-size: 14px;
    font-weight: 500;
    color: #717171;
    margin-left: 1rem;
    margin-bottom: 3px;
}

/*table style*/
.b-table {
    margin-bottom: 0;
}

.b-table thead {
    background-color: rgba(0, 0, 0, 0.03);
}

.b-table>tbody>tr:nth-child(odd)>td,
.b-table>tbody>tr:nth-child(odd)>th {
    background-color: #ffffff;
}

.b-table>tbody>tr:nth-child(even)>td,
.b-table>tbody>tr:nth-child(even)>th {
    background-color: rgba(0, 0, 0, 0.03);
}

.b-table>thead>tr>th {
    padding: 0.8rem 0 0.8rem 2rem;
    color: #456b8f;
    border-bottom: 2px solid #456b8f;
    font-weight: 500;
}

.b-table>tbody>tr>td {
    padding-left: 2rem;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    color: #717171db;
}

.table> :not(:first-child) {
    border-top: 2px solid #456b8fa8 !important;
}

/*table pagination*/
.b-pagination .page-item.active .page-link {
    background-color: #456b8f;
    border-color: #456b8f;
}

.b-pagination li.page-item {
    margin-left: 0.5rem;
}

.b-pagination li.page-item.disabled span.page-link {
    background-color: #7171714f;
    color: #717171db;
    border-radius: 0;
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.b-pagination .page-item:last-child .page-link,
.b-pagination .page-item:first-child .page-link {
    border-radius: 0 !important;
}

.pagination-sm .page-link {
    padding: 0.1rem 0.5rem !important;
    color: #456b8f;
    border: 1.5px solid #456b8f;
}

.b-pagination li.page-item button[aria-label="Go to next page"],
.b-pagination li.page-item button[aria-label="Go to last page"],
.b-pagination li.page-item button[aria-label="Go to previous page"],
.b-pagination li.page-item button[aria-label="Go to first page"] {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

/*modal*/
.long-modal {
    max-width: 600px !important;
}

/*list group*/
.list-group .list-group-item {
    margin-bottom: 0.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    border: 0;
    box-shadow: 0.2rem 0.2rem 0.1rem rgb(0 0 0 / 8%);
    border-radius: 0;
    cursor: pointer;
}

.list-group .list-group-item svg {
    color: #406892;
}

.actions-container {
    margin-bottom: 1.5rem !important;
}

/* details*/
.details-title {
    font-size: 15px;
    font-weight: 500;
    color: #717171;
}

.details-title-value {
    color: #406892;
    font-weight: 500;
    font-size: 15px;
}

@media (max-width: 475px) {
    .actions-container {
        text-align: end;
    }
}

/*file-input*/
.file-input input {
    display: none;
}

.file-input label {
    font-size: 15px;
}

.file-name {
    font-size: 14px;
    font-weight: 500;
    color: #717171;
}

.nav-link {
    color: #456b8f !important;
}
</style>
