import store from "@/store";

export const roles = {
    lawyer: "FB_Lawyer",
    assistant: "FB_Assistant",
    intern: "FB_Intern",
    complianceOfficer: "FB_ComplianceOfficer",
    residentAgent: "FB_ResidentAgent",
    superintendence: "FB_Superintendence",
    official: "FB_Official",
};

export const rolePermissions = [
    //Superintendence
    {
        rol: "superintendence",
        key: 10,
        label: "SuperUsuario",
        details: [
            "Asignación de roles y permisos de la plataforma",
            "Creación de AR",
            "Creación de AC",
            "Flujo de aprobación de requerimientos de AC",
            "Modificación de AR",
            "Modificación de AC",
            "Dashboard inicial",
            "Dashboard solo de multas",
            "Acceso a reportes dinámicos",
            "Módulo de sanciones",
            "Módulo de gestion de CUR",
            "Solicitar activacion de CUR",
            "Solicitar desactivacion de CUR",
            "Configurar motivos de activacion/desactivacion",
            "Modulo de reporte de riesgos",
            "Administrar variables de riesgo",
            "Recalcular niveles de riesgos",
            "Modulo de auditoria",
            "Modulo de envio de alertas manuales",
            "Configurar tipos de alertas",
            "Acceso al modulo de abogados/firmas",
            "Actualizar cantidad de sociedades de un AR",
        ],
    },
    {
        rol: "superintendence",
        key: 13,
        label: "Administrador de roles",
        details: [
            "Asignación de roles y permisos de la plataforma",
            "Creación de AR",
            "Creación de AC",
            "Flujo de aprobación de requerimientos de AC",
            "Modificación de AR",
            "Modificación de AC",
            "Dashboard inicial",
            "Acceso a reportes dinámicos",
            "Solicitar activacion de CUR",
            "Solicitar desactivacion de CUR",
            "Configurar motivos de activacion/desactivacion",
            "Modulo de reporte de riesgos",
            "Administrar variables de riesgo",
            "Recalcular niveles de riesgos",
            "Modulo de auditoria",
            "Modulo de envio de alertas manuales",
            "Configurar tipos de alertas",
            "Acceso al modulo de abogados/firmas",
            "Actualizar cantidad de sociedades de un AR",
        ],
    },
    {
        rol: "superintendence",
        key: 14,
        label: "Administrador de Agentes residentes",
        details: ["Creación de AR", "Modificación de AR"],
    },
    {
        rol: "superintendence",
        key: 15,
        label: "Administrador de Autoridades competentes",
        details: ["Creación de AC", "Modificación de AC"],
    },
    {
        rol: "superintendence",
        key: 16,
        label: "Multas",
        details: ["Dashboard solo de multas", "Módulo de sanciones"],
    },
    {
        rol: "superintendence",
        key: 17,
        label: "Auditor",
        details: [
            "Revision de logs del sistema",
            "Dashboard inicial",
            "Acceso a reportes dinámicos",
        ],
    },
    {
        rol: "superintendence",
        key: 18,
        label: "Analista de datos",
        details: [
            "Dashboard inicial",
            "Acceso a reportes dinámicos",
            "Modulo de reporte de riesgos",
            "Acceso al modulo de abogados/firmas",
        ],
    },
    {
        rol: "superintendence",
        key: 19,
        label: "Análisis de Riesgo",
        details: ["Modulo de reporte de riesgos"],
    },
    {
        rol: "superintendence",
        key: 100,
        label: "Seguimiento",
        details: [
            "Dashboard inicial",
            "Solicitar activacion de CUR",
            "Solicitar desactivacion de CUR",
            "Modulo de envio de alertas manuales",
            "Configurar tipos de alertas",
            "Acceso al modulo de abogados/firmas",
        ],
    },
    {
        rol: "superintendence",
        key: 101,
        label: "Asesoría Legal",
        details: [
            "Dashboard inicial",
            "Solicitar activacion de CUR",
            "Solicitar desactivacion de CUR",
            "Modulo de envio de alertas manuales",
            "Configurar tipos de alertas",
        ],
    },
    {
        rol: "superintendence",
        key: 102,
        label: "Gestor de Alertas y Notificaciones",
        details: [
            "Modulo de envio de alertas manuales",
            "Configurar tipos de alertas",
        ],
    },
    {
        rol: "superintendence",
        key: 103,
        label: "Administrador Técnico",
        details: [
            "Asignación de roles y permisos de la plataforma",
            "Revision de logs del sistemas",
            "Dashboard inicial",
            "Acceso a reportes dinámicos",
            "Configurar motivos de activacion/desactivacion",
            "Modulo de reporte de riesgos",
            "Administrar variables de riesgo",
            "Modulo de auditoria",
            "Configurar tipos de alertas",
        ],
    },
    {
        rol: "superintendence",
        key: 104,
        label: "Procesos Superintendente",
        details: [
            "Flujo de aprobación de requerimientos de AC",
            "Aprobacion final de flujo de requerimiento de AC",
            "Dashboard inicial",
            "Acceso a reportes dinámicos",
            "Módulo de gestion de CUR",
            "Acceso al modulo de abogados/firmas",
        ],
    },
    {
        rol: "superintendence",
        key: 105,
        label: "Primera Aprobación de AC",
        details: [
            "Solo permitirá la primera aprobación del flujo de solicitudes AC."
        ],
    },
    {
        rol: "superintendence",
        key: 106,
        label: "Segunda Aprobación de AC",
        details: [
            "Solo permitirá la segunda aprobación del flujo de solicitudes AC.",
        ],
    },
    //ResidentAgent
    {
        rol: "residentAgent",
        key: 20,
        label: "SuperUsuario",
        details: [
            "Asignación de roles y permisos de la plataforma",
            "Creación de roles",
            "Gestión de personas juridicas y/o beneficiarios finales",
            "Dashboard inicial",
        ],
    },
    {
        rol: "residentAgent",
        key: 21,
        label: "Administrador de roles",
        details: [
            "Creación de roles",
            "Gestión de personas juridicas y/o beneficiarios finales",
            "Dashboard inicial",
        ],
    },
    {
        rol: "residentAgent",
        key: 22,
        label: "Usuario cumplimiento",
        details: [
            "Gestión de personas juridicas y/o beneficiarios finales",
            "Dashboard inicial",
        ],
    },
    {
        rol: "residentAgent",
        key: 23,
        label: "Acceso a la información de beneficiario final",
        details: ["Dashboard inicial"],
    },
    {
        rol: "residentAgent",
        key: 24,
        label: "Carga y descarga información de beneficiario final",
        details: [
            "Gestión de personas juridicas y/o beneficiarios finales",
            "Dashboard inicial",
        ],
    },
    {
        rol: "residentAgent",
        key: 25,
        label: "Dashboard",
        details: ["Dashboard inicial"],
    },
    //Official
    {
        rol: "official",
        key: 30,
        label: "SuperUsuario",
        details: [
            "Asignación de roles y permisos de la plataforma ",
            "Creación y asignación de roles ",
            "Requerimiento de información de beneficiario finales ",
            "Dashboard de seguimiento requerimientos ",
            "Reporte de resultados de requerimientos ",
            "Revisión de logs de sistemas ",
            "Dashboard inicial ",
            "Acceso a reportes dinámicos de req. de información ",
            "Dashboard inicial de datos generales",
        ],
    },
    {
        rol: "official",
        key: 31,
        label: "Administrador de roles",
        details: [
            "Creación y asignación de roles",
            "Dashboard inicial de datos generales",
        ],
    },
    {
        rol: "official",
        key: 32,
        label: "Procesos de beneficiario final",
        details: [
            "Requerimiento de información de beneficiario finales",
            "Dashboard de seguimiento requerimientos",
            "Reporte de resultados de requerimientos",
            "Dashboard inicial de datos generales",
        ],
    },
];

function userIsInRole(role) {
    let user = getUser();
    if (user === null) {
        return false;
    }
    var index = user.role.indexOf(role);
    return index > -1;
}

function getUser() {
    return store.state.oidcStore.user;
}

function isLogged() {
    return store.getters["oidcStore/oidcIsAuthenticated"];
}

// USERS ROLES CHECK

function isLawyer() {
    let result = userIsInRole(roles.lawyer);
    return result;
}

function isAssistant() {
    let result = userIsInRole(roles.assistant);
    return result;
}

function isIntern() {
    let result = userIsInRole(roles.intern);
    return result;
}

function isComplianceOfficer() {
    let result = userIsInRole(roles.complianceOfficer);
    return result;
}

function isResidentAgent() {
    let result = userIsInRole(roles.residentAgent);
    return result;
}

function isSuperintendence() {
    let result = userIsInRole(roles.superintendence);
    return result;
}

function isOfficial() {
    let result = userIsInRole(roles.official);
    return result;
}

export default {
    getUser,
    isLogged,
    isLawyer,
    isAssistant,
    isIntern,
    isComplianceOfficer,
    isResidentAgent,
    isSuperintendence,
    isOfficial,
    rolePermissions,
};
