<template>
  <div v-if="isResidentAgent">
    <div v-for="(notification, index) in notifications" :key="index">
      <b-modal
        v-model="manualNotificationModals[index]"
        content-class="rounded-0"
        dialog-class="long-modal"
        header-class="border-0"
        centered
        hide-header
        hide-footer
        no-close-on-backdrop
      >
        <h6 class="page-title pb-2 mt-3 text-uppercase">Notificación</h6>

        <p>{{ getMessage(notification) }}</p>

        <div class="text-end">
          <b-button
            class="main-btn p-2"
            @click="readModal(notification.id, index)"
          >
            <span>Aceptar</span>
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { alertStatus } from "@/config/alertStatus";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "alert-manual-notification-lawfirm",
  props: {
    isResidentAgent: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      manualNotificationModals: [],
      notifications: null,
      statusRead: alertStatus.find((x) => x.label === "Read").key,
    };
  },
  computed: {
    ...mapGetters("application", ["getModalAlertManualNotificationLawFirm"]),
    modalAlertManualNotificationLawFirm() {
      return this.getModalAlertManualNotificationLawFirm;
    },
  },
  watch: {
    isResidentAgent() {
      this.getNotification();
    },
    modalAlertManualNotificationLawFirm(value) {
      this.notifications = [value];
      this.manualNotificationModals = new Array(this.notifications.length).fill(
        true
      );
    },
  },
  methods: {
    ...mapActions("application", ["setAlertManualNotificationLawFirm"]),
    async getNotification() {
      try {
        let response =
          await RepositoryFactory.alertRepository.manualNotificationForLawFirm();
        this.notifications = response.data;

        this.manualNotificationModals = new Array(
          this.notifications.length
        ).fill(true);

        this.setAlertManualNotificationLawFirm(this.notifications);
      } catch (error) {
        console.log(error);
      }
    },
    async updateStatus(id, status) {
      let payload = {
        id: id,
        status: status,
      };
      return await RepositoryFactory.alertRepository.updateManualNotificationLawFirmStatus(
        payload
      );
    },
    async readModal(id, index) {
      try {
        await this.updateStatus(id, this.statusRead);
        this.closeModal(index);
      } catch (error) {
        console.log(error);
      }
    },
    closeModal(index) {
      this.$set(this.manualNotificationModals, index, false);
    },
    getMessage(notification) {
      let message = notification.alertManualNotification.message;
      return message;
    },
  },
};
</script>
