import Vue from "vue";

var allow = (state, value) => state.roles.indexOf(value) > -1;

var isSuperUser = (state) => allow(state, 10) || allow(state, 20) || allow(state, 30); //Perfil Superintendencia - Perfil Abogados y Firmas - Perfil Autoridades Competentes -> Super Usuario
var isRoleAdministrator = (state) => allow(state, 13) || allow(state, 21) || allow(state, 31); //Perfil Superintendencia - Perfil Abogados y Firmas - Perfil Autoridades Competentes -> Usuario administrador de roles

var isResidentAgentAdministrator = (state) => allow(state, 14); //Perfil Superintendencia -> Usuario autorizador de Firmas y Abogados
var isCompetentAuthoritiesAdministrator = (state) => allow(state, 15); //Perfil Superintendencia -> Usuario autorizador de Autoridad Competente
var isPenalty = (state) => allow(state, 16); //Perfil Superintendencia -> Usuario con autorización para ver los temas de multas
var isAuditor = (state) => allow(state, 17) || allow(state, 34); //Perfil Superintendencia - Perfil Autoridades Competentes -> Usuario Auditor
var isDataAnalyst = (state) => allow(state, 18); //Perfil Superintendencia -> Usuario Analista de datos
var isRiskAnalysis = (state) => allow(state, 19); //Perfil Superintendencia -> Usuario Análisis de Riesgo
var isFollowUp = (state) => allow(state, 100); //Perfil Superintendencia -> Usuario de Seguimiento
var isLegalAdvice = (state) => allow(state, 101); //Perfil Superintendencia -> Usuario de Asesoría Legal
var isAlertsAndNotificationsManager = (state) => allow(state, 102); //Perfil Superintendencia -> Usuario Gestor de Alertas y Notificaciones
var isTechnicalAdministrator = (state) => allow(state, 103); //Perfil Superintendencia -> Usuario Administrador Técnico del RUBF
var isSuperintendentProcesses = (state) => allow(state, 104); //Perfil Superintendencia -> Procesos Superintendente
var isFirstApproved = (state) => allow(state, 105); //Perfil Superintendencia -> Primera aprobacion de tramite AC
var isSecondApproved = (state) => allow(state, 106); //Perfil Superintendencia -> Segunda aprobacion de tramite AC

var isCompliance = (state) => allow(state, 22); //Perfil Abogados y Firmas -> Usuario Cumplimiento
var isReadFinalBeneficiaries = (state) => allow(state, 23) || allow(state, 32); //Perfil Abogados y Firmas - Perfil Autoridades Competentes -> Usuario con acceso a la información de beneficiario final ó Usuario autorizado a descargar información de beneficiario final
var isUpdateFinalBeneficiaries = (state) => allow(state, 24); //Perfil Abogados y Firmas -> Usuario que carga y descarga información de beneficiario final
var onlyDashboard = (state) => allow(state, 25) || allow(state, 34); //Perfil Abogados y Firmas - Perfil Autoridades Competentes -> Usuario con acceso solo al dashboard

export const application = {
    namespaced: true,
    state: {
        isResidentAgent: false,
        isSuperintendence: false,
        isOfficial: false,

        isAdmin: false,

        roles: [],
        enabledCriteria: false,
        alertManualNotificationLawFirm: [],
        alertDynamicNotificationLawFirm: [],
        alertAutomaticNotificationLawFirm: [],
        modalAlertManualNotificationLawFirm: null,
        modalAlertDynamicNotificationLawFirm: null,
        modalAlertAutomaticNotificationLawFirm: null
    },
    actions: {
        setResidentAgent({ commit }, { roles }) {
            commit('setResidentAgent');
            commit('setRoles', { roles });
        },
        setSuperintendence({ commit }, { roles }) {
            commit('setSuperintendence');
            commit('setRoles', { roles });
        },
        setOfficial({ commit }, { roles }) {
            commit('setOfficial');
            commit('setRoles', { roles });
        },
        setIsAdmin({ commit }, {value}) {
            commit('setIsAdmin', {value});
        },
        setEnabledCriteria({ commit }, value) {
            commit('setEnabledCriteria', { value });
        },
        setAlertManualNotificationLawFirm({ commit }, value) {
            commit('setAlertManualNotificationLawFirm', { value });
        },
        setAlertDynamicNotificationLawFirm({ commit }, value) {
            commit('setAlertDynamicNotificationLawFirm', { value });
        },
        setAlertAutomaticNotificationLawFirm({ commit }, value) {
            commit('setAlertAutomaticNotificationLawFirm', { value });
        },
        setModalAlertManualNotificationLawFirm({ commit }, value) {
            commit('setModalAlertManualNotificationLawFirm', { value });
        },
        setModalAlertDynamicNotificationLawFirm({ commit }, value) {
            commit('setModalAlertDynamicNotificationLawFirm', { value });
        },
        setModalAlertAutomaticNotificationLawFirm({ commit }, value) {
            commit('setModalAlertAutomaticNotificationLawFirm', { value });
        },
    },
    mutations: {
        setResidentAgent(state) {
            Vue.set(state, 'isResidentAgent', true);
        },
        setSuperintendence(state) {
            Vue.set(state, 'isSuperintendence', true);
        },
        setOfficial(state) {
            Vue.set(state, 'isOfficial', true);
        },
        setIsAdmin(state, {value}) {
            Vue.set(state, 'isAdmin', value);
        },
        setRoles(state, { roles }) {
            Vue.set(state, 'roles', roles);
        },
        setEnabledCriteria(state, { value }) {
            Vue.set(state, 'enabledCriteria', value);
        },
        setAlertManualNotificationLawFirm(state, { value }) {
            Vue.set(state, 'alertManualNotificationLawFirm', value);
        },
        setAlertDynamicNotificationLawFirm(state, { value }) {
            Vue.set(state, 'alertDynamicNotificationLawFirm', value);
        },
        setAlertAutomaticNotificationLawFirm(state, { value }) {
            Vue.set(state, 'alertAutomaticNotificationLawFirm', value);
        },
        setModalAlertManualNotificationLawFirm(state, { value }) {
            Vue.set(state, 'modalAlertManualNotificationLawFirm', value);
        },
        setModalAlertDynamicNotificationLawFirm(state, { value }) {
            Vue.set(state, 'modalAlertDynamicNotificationLawFirm', value);
        },
        setModalAlertAutomaticNotificationLawFirm(state, { value }) {
            Vue.set(state, 'modalAlertAutomaticNotificationLawFirm', value);
        },
    },
    getters: {
        isResidentAgent: state => state.isResidentAgent,
        isSuperintendence: state => state.isSuperintendence,
        isOfficial: state => state.isOfficial,

        isAdmin: state => state.isAdmin,

        isSuperUser: state => isSuperUser(state),
        
        getEnabledCriteria: state => state.enabledCriteria,
        getAlertManualNotificationLawFirm: state => state.alertManualNotificationLawFirm,
        getAlertDynamicNotificationLawFirm: state => state.alertDynamicNotificationLawFirm,
        getAlertAutomaticNotificationLawFirm: state => state.alertAutomaticNotificationLawFirm,
        getModalAlertManualNotificationLawFirm: state => state.modalAlertManualNotificationLawFirm,
        getModalAlertDynamicNotificationLawFirm: state => state.modalAlertDynamicNotificationLawFirm,
        getModalAlertAutomaticNotificationLawFirm: state => state.modalAlertAutomaticNotificationLawFirm,

        // Superintendence        
        allowSuperintendenceAsignRoles: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isTechnicalAdministrator(state)), //ASIGNACION DE ROLES Y PERMISOS DE LA PLATAFORMA        
        allowSuperintendenceCreateRA: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isResidentAgentAdministrator(state)), //CREACIÓN DE AR
        allowSuperintendenceCreateCA: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isCompetentAuthoritiesAdministrator(state)), //CREACIÓN DE AC
        allowSuperintendenceFirstProcedureValidation: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isSuperintendentProcesses(state) || isFirstApproved(state)) , //FLUJO DE APROBACIÓN DE REQUERIMIENTOS DE AC
        allowSuperintendenceLastProcedureValidation: state => state.isSuperintendence && isSuperintendentProcesses(state) || isSecondApproved(state), //APROBACIÓN FINAL DE FLUJO DE REQ. AC
        allowSuperintendenceLogs: state => state.isSuperintendence && (isSuperUser(state) || isAuditor(state) || isTechnicalAdministrator(state)), //REVISIÓN DE LOGS DEL SISTEMAS
        allowSuperintendenceModifyRA: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isResidentAgentAdministrator(state)), //MODIFICACIÓN DE AR
        allowSuperintendenceModifyCA: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isCompetentAuthoritiesAdministrator(state)), //MODIFICACIÓN DE AC
        allowSuperintendenceDashboard: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isAuditor(state) || isDataAnalyst(state) || isFollowUp(state) || isLegalAdvice(state) || isTechnicalAdministrator(state) || isSuperintendentProcesses(state)), //DASHBOARDS INICIAL
        allowSuperintendencePenaltyDashboard: state => state.isSuperintendence && (isSuperUser(state) || isPenalty(state)), //DASHBOARD SOLO DE MULTAS
        allowSuperintendenceDynamicReports: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isAuditor(state) || isDataAnalyst(state) || isTechnicalAdministrator(state) || isSuperintendentProcesses(state)), //ACCESO A REPORTES DINÁMICOS
        allowSuperintendencePenalty: state => state.isSuperintendence && (isSuperUser(state) || isPenalty(state)), //MÓDULO DE SANSIONES
        allowSuperintendenceCurManagement: state => state.isSuperintendence && (isSuperUser(state) || isSuperintendentProcesses(state)), //MÓDULO DE GESTION DE CUR
        allowSuperintendenceCurActivation: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isFollowUp(state) || isLegalAdvice(state)), //SOLICITAR ACTIVACIÓN DE CUR
        allowSuperintendenceCurDeactivation: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isFollowUp(state) || isLegalAdvice(state)), //SOLICITAR DESACTIVACION DE CUR
        allowSuperintendenceConfigureCriteria: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isTechnicalAdministrator(state)), //CONFIGURAR MOTIVOS DE ACTIVACION/DESACTIVACION
        allowSuperintendenceReadRiskReport: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isDataAnalyst(state) || isRiskAnalysis(state) || isTechnicalAdministrator(state)), //MÓDULO REPORTE DE RIESGO
        allowSuperintendenceManageRiskVariables: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isTechnicalAdministrator(state)), //ADMINISTRAR VARIABLES DE RIESGO
        allowSuperintendenceRecalculateRiskLevels: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state)), //RECALCULAR NIVELES DE RIESGO
        allowSuperintendenceReadAudit: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isTechnicalAdministrator(state)), //MODULO DE AUDITORÍA
        allowSuperintendenceManualAlerts: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isFollowUp(state) || isLegalAdvice(state) || isAlertsAndNotificationsManager(state)), //MÓDULO ENVIO DE ALERTAS MANUALES 
        allowSuperintendenceConfigureAlertTypes: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isFollowUp(state) || isLegalAdvice(state) || isAlertsAndNotificationsManager(state) || isTechnicalAdministrator(state)), //CONFIGURAR TIPOS DE ALERTAS
        allowSuperintendenceReadLawyersFirms: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state) || isDataAnalyst(state) || isFollowUp(state) || isSuperintendentProcesses(state)), //ACCESO AL MODULO DE ABOGADOS/FIRMAS
        allowSuperintendenceUpdateCompaniesAR: state => state.isSuperintendence && (isSuperUser(state) || isRoleAdministrator(state)), //ACTUALIZAR CANTIDAD DE SOCIEDADES DE UN AR

        // Resident Agent
        allowResidentAgentAsignRoles: state => state.isResidentAgent && (isSuperUser(state)), //ASIGNACION DE ROLES Y PERMISOS DE LA PLATAFORMA
        allowResidentAgentCreateRoles: state => state.isResidentAgent && (isSuperUser(state) || isRoleAdministrator(state)), //CREACIÓN DE ROLES
        allowResidentAgentAdminFinalBeneficiaries: state => state.isResidentAgent && (isSuperUser(state) || isRoleAdministrator(state) || isCompliance(state) || isUpdateFinalBeneficiaries(state)), //GESTIÓN DE PERSONAS JURÍDICAS Y/O BENEFICIARIOS FINALES
        allowResidentAgentDashboard: state => state.isResidentAgent, //DASHBOARDS INICIAL

        // Official
        allowOfficialAsignRoles: state => state.isOfficial && (isSuperUser(state)), //ASIGNACION DE ROLES Y PERMISOS DE LA PLATAFORMA
        allowOfficialCreateAndAsignRoles: state => state.isOfficial && (isSuperUser(state) || isRoleAdministrator(state)), //CREACIÓN Y ASIGNACIÓN DE ROLES 
        allowOfficialStartProcedure: state => state.isOfficial && isReadFinalBeneficiaries(state), //REQUERIMIENTO DE INFORMACIÓN DE BENEFICIARIO FINALES
        allowOfficialProcedureDashboard: state => state.isOfficial && isReadFinalBeneficiaries(state), //DASHBOARD DE SEGUIMIENTO REQUERIMIENTOS
        allowOfficialProcedureFinishedReport: state => state.isOfficial && isReadFinalBeneficiaries(state), //REPORTE DE RESULTADOS DE REQUERIMIENTO
        allowOfficialLogs: state => state.isOfficial && isAuditor(state), //REVISIÓN DE LOGS DEL SISTEMAS
        allowOfficialDashboard: state => state.isOfficial && isAuditor(state), //DASHBOARDS INICIAL
        allowOfficialDynamicReports: state => state.isOfficial && isAuditor(state), //ACCESO A REPORTES DINÁMICOS DE REQ. DE INFORMACIÓN
        allowOfficialDashboardStadistics: state => state.isOfficial && (isSuperUser(state) || isRoleAdministrator(state) || isReadFinalBeneficiaries(state) || isAuditor(state) || onlyDashboard(state)), //"DASHBOARDS INICIAL DE DATOS GENERALES
    }
}