import { RepositoryFactory } from "@/services/api";

import usersManagement from "@/config/roles";
import store from "@/store";

async function getRegisteredResidentAgent() {
    try {
        let response = await RepositoryFactory.residentAgent.getByUser();
        return {
            user: response.data,
            success: response.status === 200
        };
    } catch {
        return { user: null, success: false };
    }
}

async function getRegisteredOfficial() {
    try {
        let response = await RepositoryFactory.official.getByUser();
        return {
            user: response.data,
            success: response.status === 200
        };
    } catch {
        return { user: null, success: false };
    }
}

async function getRegisteredSuperintendence() {
    try {
        let response = await RepositoryFactory.superintendence.getByUser();
        return {
            user: response.data,
            success: response.status === 200
        };
    } catch {
        return { user: null, success: false };
    }
}

async function getRegisteredUser() {
    let response = {};
    try {
        if (usersManagement.isResidentAgent()) {
            response = await getRegisteredResidentAgent();
            if (response.success) {
                store.dispatch("application/setResidentAgent", { roles: response.user.roles });
                store.dispatch("application/setIsAdmin", { value: response.user.isAdmin });
                return response.user;
            }
        }

        if (usersManagement.isOfficial()) {
            response = await getRegisteredOfficial();
            if (response.success) {
                store.dispatch("application/setOfficial", { roles: response.user.roles });
                return response.user;
            }
        }

        if (usersManagement.isSuperintendence()) {
            response = await getRegisteredSuperintendence();
            if (response.success) {
                store.dispatch("application/setSuperintendence", { roles: response.user.roles });
                return response.user;
            }
        }
    } catch {
        return null;
    }

    if (!response.success) {
        return null;
    }

    let user = response.user;
    return user;
}

async function getEnabledCriteria() {
    try {
        let response = await RepositoryFactory.residentAgent.getEnabledCriteria();
        return response.data;
    } catch {
        return false;
    }
}

export async function isUserRegisteredAndEnabled() {
    let user = await getRegisteredUser();
    if (user === null) {
        return false;
    }
    if (user.isEnabled === false) {
        return false;
    }
    return true;
}

export async function isUserRegistered() {
    let registeredUser = false;
    registeredUser = await getRegisteredUser() !== null;
    return registeredUser;
}

export async function isEnabledCriteria() {
    let result = await getEnabledCriteria();
    store.dispatch("application/setEnabledCriteria", result);
    return result;
}