import Repository from "./repository";

const resource = "event";

export default {
    async getPagedEvents({ page, take, userId, eventType, startDate, endDate, folio, identification, idoneidad, userType, sessionId }) {
        let endpoint = `${resource}?page=${page}&take=${take}`;

        if (userId !== null) {
            endpoint += `&userId=${userId}`;
        }
        if (eventType !== null) {
            endpoint += `&eventType=${eventType}`;
        }
        if (folio !== null && folio !== '') {
            endpoint += `&folio=${folio}`;
        }
        if (identification !== null && identification !== '')  {
            endpoint += `&identification=${identification}`;
        }
        if (idoneidad !== null && idoneidad !== '') {
            endpoint += `&idoneidad=${idoneidad}`;
        }
        if (startDate !== null && startDate !== "Invalid date") {
            endpoint += `&startDate=${startDate}`;
        }
        if (endDate !== null && endDate !== "Invalid date") {
            endpoint += `&endDate=${endDate}`;
        }
        if (userType !== null && userType !== '') {
            endpoint += `&userType=${parseInt(userType)}`;
        }
        if (sessionId !== null) {
            endpoint += `&sessionId=${sessionId}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getAllUsers({ userType }) {
        let endpoint = `${resource}/users`;
        if (userType !== null) {
            endpoint += `?userType=${userType}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getSessions() {
        let endpoint = `${resource}/sessions`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async add({ code, details, sub }) {
        try {            
            let endpoint = `${resource}`;
            const data = {
                eventType: code,
                payload: details,
                internalId: sub
            }
            let response = await Repository.post(endpoint, data);
            return response;
        } catch (error){ console.log(error.response) }
    }
}

