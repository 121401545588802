import Repository from "./repository";

const resource = "legalEntity"

export default {
    async get({ id, procedureId }) {
        let endpoint = `${resource}/${id}`;
        if (procedureId !== null && procedureId !== undefined) {
            endpoint += `/${procedureId}`
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async add(payload) {
        let endpoint = `${resource}`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async disable(payload) {
        let endpoint = `${resource}/disable`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async update(payload) {
        let endpoint = `${resource}/${payload.id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async getPaged({ page, take, search, enabled, enabledCriteria }) {
        let endpoint = `${resource}/paged?page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        if (enabled !== undefined && enabled !== null) {
            endpoint += `&enabled=${enabled}`;
        }
        if (enabledCriteria !== undefined && enabledCriteria !== null) {
            endpoint += `&enabledCriteria=${enabledCriteria}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async getFinalBeneficiaries({ id }) {
        let endpoint = `${resource}/${id}/final-beneficiary`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async getFinalBeneficiary({ id }) {
        let endpoint = `${resource}/final-beneficiary/${id}`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async addFinalBeneficiary(payload) {
        let endpoint = `${resource}/final-beneficiary`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async updateFinalBeneficiary(payload, id) {
        let endpoint = `${resource}/final-beneficiary/${id}`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async deleteFinalBeneficiary({ id }) {
        let endpoint = `${resource}/final-beneficiary/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getDirectors({ id }) {
        let endpoint = `${resource}/${id}/directors`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async addDirector(payload) {
        let endpoint = `${resource}/director`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async deleteDirector({ id }) {
        let endpoint = `${resource}/director/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getShareholders({ id }) {
        let endpoint = `${resource}/${id}/shareholders`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async addShareholder(payload) {
        let endpoint = `${resource}/shareholder`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async deleteShareholder({ id }) {
        let endpoint = `${resource}/shareholder/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async getLegalRepresentatives({ id }) {
        let endpoint = `${resource}/${id}/legal-representatives`;
        let response = await Repository.get(endpoint);
        return response;
    },
    async addLegalRepresentative(payload) {
        let endpoint = `${resource}/legal-representative`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async deleteLegalRepresentative({ id }) {
        let endpoint = `${resource}/legal-representative/${id}`;
        let response = await Repository.delete(endpoint);
        return response;
    },
    async certificateOfSeparation({ id }) {
        let endpoint = `${resource}/certificateOfSeparation/${id}`;
        let response = await Repository.getBlob(endpoint);
        return response;
    },
    async any({ folio, societyType, enabled }) {
        let endpoint = `${resource}/${folio}/${societyType}/any`;
        if (enabled !== undefined && enabled !== null) {
            endpoint += `/${enabled}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async export({ search, enabled, enabledCriteria }) {
        if (search === undefined || search === null) {
            search = "";
        }
        let endpoint = `${resource}/export?search=${search}`;
        if (enabled !== undefined && enabled !== null) {
            endpoint += `&enabled=${enabled}`;
        }
        if (enabledCriteria !== undefined && enabledCriteria !== null) {
            endpoint += `&enabledCriteria=${enabledCriteria}`;
        }
        let response = await Repository.getBlob(endpoint);
        return response;
    },
    async getReasonDisablePaged({ page, take, search }) {
        let endpoint = `${resource}/reasonDisable/paged?page=${page}&take=${take}`;
        if (search !== undefined && search !== null) {
            endpoint += `&search=${search}`;
        }
        let response = await Repository.get(endpoint);
        return response;
    },
    async addReasonDisable(payload) {
        let endpoint = `${resource}/reasonDisable`;
        let response = await Repository.post(endpoint, payload);
        return response;
    },
    async updateReasonDisable(payload) {
        let endpoint = `${resource}/reasonDisable`;
        let response = await Repository.put(endpoint, payload);
        return response;
    },
    async getReasonDisableAll() {
        let endpoint = `${resource}/reasonDisableAll`;
        let response = await Repository.get(endpoint);
        return response;
    },
}