import Vue from "vue";

export const StadisticStore = {
    namespaced: true,
    state: {
        arRegisteredChart: null,
        arProductionChart: null,
        loadYearlySummaryChart: null,
        pjInPanamaChart: null,
        pjOutPanamaChart: null,
    },
    actions: {
        setRegisteredChart({ commit }, arRegisteredChart) {
            commit('setRegisteredChart', { arRegisteredChart });
        },
        setProductionChart({ commit }, arProductionChart) {
            commit('setProductionChart', { arProductionChart });
        },
        setLoadYearlySummaryChart({ commit }, loadYearlySummaryChart) {
            commit('setLoadYearlySummaryChart', { loadYearlySummaryChart });
        },
        setPJInPanamaChart({ commit }, pjInPanamaChart) {
            commit('setPJInPanamaChart', { pjInPanamaChart });
        },
        setPJOutPanamaChart({ commit }, pjOutPanamaChart) {
            commit('setPJOutPanamaChart', { pjOutPanamaChart });
        },
    },
    mutations: {
        setRegisteredChart(state, { arRegisteredChart }) {
            Vue.set(state, 'arRegisteredChart', arRegisteredChart);
        },
        setProductionChart(state, { arProductionChart }) {
            Vue.set(state, 'arProductionChart', arProductionChart);
        },
        setLoadYearlySummaryChart(state, { loadYearlySummaryChart }) {
            Vue.set(state, 'loadYearlySummaryChart', loadYearlySummaryChart);
        },
        setPJInPanamaChart(state, { pjInPanamaChart }) {
            Vue.set(state, 'pjInPanamaChart', pjInPanamaChart);
        },
        setPJOutPanamaChart(state, { pjOutPanamaChart }) {
            Vue.set(state, 'pjOutPanamaChart', pjOutPanamaChart);
        },
    },
    getters: {
        getRegisteredChart: state => state.arRegisteredChart,
        getProductionChart: state => state.arProductionChart,
        getLoadYearlySummaryChart: state => state.loadYearlySummaryChart,
        getPJInPanamaChart: state => state.pjInPanamaChart,
        getPJOutPanamaChart: state => state.pjOutPanamaChart,
    }
}